import en from './en';

type i18nKeys = keyof typeof en;
type i18nValues = (typeof en)[i18nKeys];

const de: Record<i18nKeys, i18nValues> = {
    mmyyyy: {
        year: 'numeric',
        month: '2-digit',
    },
    ddmmyyyy: {
        day: '2-digit',
        year: 'numeric',
        month: '2-digit',
    },
    hh: {
        hour: 'numeric',
    },
};

export default de;
