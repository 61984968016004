import en from './en';

import { AssetDoors, AssetEuroNorm, AssetFuel, AssetGearboxType, AssetPartPosition, AssetSeatUpholstery, AssetStateOfUse, AssetTachograph, AssetType, AssetUseType, AssetWheelchairRamp, AuctionState, BackendErrorCode, BusClass, CruiseControlType, DeckType, GasTanksMaterials, Glasses, RequireScrappingFilter, UserType, VehicleCondition } from '~/apiClient';

type i18nKeys = keyof typeof en;
type i18nValues = (typeof en)[i18nKeys];

const de: Record<i18nKeys, i18nValues> = {
    account: {
        bids: {
            title: 'Ihre Gebote',
            emptyState: {
                base: 'Sie haben noch kein Gebot in einer Auktion abgegeben,',
                cta: 'An Auktionen teilnehmen',
            },
            filters: {
                bidPosition: {
                    base: 'Gebotsposition',
                    anyOption: 'Alle Gebotspositionen',
                    maxBidder: 'Ihr Gebot ist das höchste',
                    outbid: 'Sie wurden überboten',
                },
                state: {
                    base: 'Auktionsstatus',
                    anyOption: 'Alle Auktionsstatus',
                    [AuctionState.Closed]: 'Geschlossene Auktionen',
                    [AuctionState.Running]: 'Laufende Auktionen',
                },
            },
        },
        sales: {
            title: 'Ihre Verkäufe',
            emptyStateText: 'Es gibt keine Auktionen mit einem Verkäufer, der diesem Konto zugeordnet ist.',
        },
    },
    common: {
        logout: 'Abmelden',
        login: 'Anmelden',
        loginSignup: 'Anmelden/Registrieren',
        loginTitle: 'Bei Fleequid anmelden',
        profile: 'Ihr Profil',
        searchPlaceholder: 'Suche …',
        searchNoResults: 'No results found for "{query}"',
        email: 'E-Mail-Adresse',
        yourAccount: 'Ihr Konto',
        language: 'Sprache',
    },
    cookie: {
        name: 'Vorname',
        provider: 'Anbieter',
        purpose: 'Zweck',
        expiry: 'Ablaufdatum',
        type: 'Typ',
    },
    error: {
        backend: {
            [BackendErrorCode.InvalidEnumValue]: 'Datentyp ungültig',
            [BackendErrorCode.InvalidDate]: 'Datumsformat ungültig',
            [BackendErrorCode.InvalidLength]: 'Länge ungültig. Zeichenkette auf fehlende oder zusätzliche Zeichen prüfen',
            [BackendErrorCode.NotAString]: 'Datentyp ungültig; nur Buchstaben zulässig',
            [BackendErrorCode.EmptyString]: 'Fehlende Angaben. Bitte geben Sie alle erforderlichen Daten an',
            [BackendErrorCode.PrismaError]: 'Die Daten konnten nicht gespeichert werden; bitte versuchen Sie es mit einem anderen Wert',
            [BackendErrorCode.AuthAuthenticationVersionMismatch]: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an, um fortzufahren',
            [BackendErrorCode.AuthWrongCredentials]: 'Anmeldedaten ungültig',
            [BackendErrorCode.AuthNoUserByEmail]: 'Mit dieser E-Mail-Adresse ist kein Konto verknüpft.',
            [BackendErrorCode.AuthWrongPassword]: 'Das eingegebene Passwort ist falsch.',
            [BackendErrorCode.UserIsBanned]: 'Ihr Konto wurde gesperrt. Bitte kontaktieren Sie uns für weitere Informationen.',
            [BackendErrorCode.AuthEmailNotVerified]: 'Die eingegebene E-Mail-Adresse wurde noch nicht verifiziert. Überprüfen Sie Ihren E-Mail-Posteingang oder kontaktieren Sie uns.',
            [BackendErrorCode.SignupUserAlreadyExists]: 'E-Mail-Adresse bereits registriert, wenn Sie Ihr Passwort vergessen haben, versuchen Sie es wiederherzustellen.',
            [BackendErrorCode.UploadFileError]: 'Fehler beim Hochladen der Datei',
            [BackendErrorCode.IsNotTrue]: 'Fehlende Auswahl: Wählen Sie die gewünschte Option, um fortzufahren',
            [BackendErrorCode.EntityNotFound]: 'Keine Ergebnisse gefunden',
            [BackendErrorCode.PasswordResetEmailNotExists]: 'Mit dieser E-Mail-Adresse ist kein Konto verknüpft',
            [BackendErrorCode.EmailChangeWrongPassword]: 'Sie können die E-Mail-Adresse nicht ändern, da das Passwort falsch ist',
            [BackendErrorCode.CantBidUserCompanyEqSellerCompany]: 'Dieses Asset ist von Ihrem Unternehmen, Sie können es nicht kaufen.',
            authentication: 'Authentifizierungsfehler; bitte melden Sie sich erneut an',
        },
        '404': {
            title: '404',
            subtitle: 'Hoppla! Seite nicht gefunden',
            description: 'Entschuldigung, die angeforderte Seite existiert nicht. Trotzdem gibt es noch viel mehr auf Fleequid zu entdecken.',
            button: 'Zurück zur Startseite',
        },
        '500': {
            title: '500',
            subtitle: 'Hoppla! Ein Fehler ist aufgetreten',
            description: 'Wir entschuldigen uns für die Unannehmlichkeiten. Wir arbeiten bereits an der Lösung des Problems.',
            help: {
                base: 'In der Zwischenzeit können Sie es später noch einmal versuchen oder {contactUs}',
                contactUs: 'In dringenden Fällen erreichen Sie uns unter {email} oder {phone}',
            },
            button: 'Zurück zur Startseite',
        },
        default: {
            title: 'Fehler',
            subtitle: 'Wir haben einen unerwarteten Fehler festgestellt',
            description: 'Wir arbeiten bereits an der Lösung des Problems.',
            button: 'Zurück zur Startseite',
        },
    },
    a11y: {
        auctionDetail: 'Mehr über {name}',
    },
    adp: {
        imageGallery: 'Gesamte Galerie ({totalImages})',
        videoGallery: 'Videos ansehen ({totalVideos})',
        imagePagination: 'Foto {0} von {1}',
        videoPagination: 'Video {0} von {1}',
        papers: 'Dokumente',
        registrationDocument: 'Fahrzeugschein',
        cocDocument: 'Konformitätsbescheinigung (CoC-Papiere)',
        cocDocumentAbsent: 'Die Konformitätsbescheinigung ist nicht verfügbar.',
        maintenanceCard: 'Wartungshistorie',
        detailsTitle: 'Fahrzeugdaten | Fahrzeugdaten',
    },
    asset: {
        type: {
            [AssetType.Bus]: 'Bus | Busse',
        },
        class: {
            base: 'Fahrzeugklasse',
            [BusClass.ClassOne]: 'Stadt',
            [BusClass.ClassTwo]: 'Intercity',
            [BusClass.ClassThree]: 'Tourismus',
            [BusClass.ClassA]: 'Fahrzeugklasse A',
            [BusClass.ClassB]: 'Fahrzeugklasse B',
            [BusClass.SchoolBus]: 'Schulbus',
        },
        firstRegistration: 'Erstzulassung',
        kilometers: 'Kilometerstand',
        antiPollution: 'Schadstoffklasse',
        euroNorm: {
            [AssetEuroNorm.Euro0]: 'Euro0',
            [AssetEuroNorm.Euro1]: 'Euro1',
            [AssetEuroNorm.Euro2]: 'Euro2',
            [AssetEuroNorm.Euro3]: 'Euro3',
            [AssetEuroNorm.Euro4]: 'Euro4',
            [AssetEuroNorm.Euro5]: 'Euro5',
            [AssetEuroNorm.Euro6]: 'Euro6',
        },
        toBeScrapped: 'Zu entfernen',
        expiredInspection: 'Inspektion abgelaufen',
        bundle: 'Bündel',
        vehicleSpecs: 'Fahrzeugspezifikationen',
        bundleSpecs: 'Bündelspezifikationen',
        vehiclesInBundle: 'Fahrzeuge im Bündel enthalten',
        vehicleIndex: 'Fahrzeug #{index}',
        vehicleCount: '{count} fahrzeuge',
        description: 'Beschreibung',
        version: 'Version',
        geographicalLocation: 'Standort',
        vehicleCondition: {
            base: 'Fahrzeugzustand',
            [VehicleCondition.Blocked]: 'Blockiert',
            [VehicleCondition.Rollable]: 'Kann abgeschleppt werden',
            [VehicleCondition.Running]: 'Läuft',
        },
        registrationDocumentCountry: 'Dokumenten-Nationalität',
        conditionsAndMaintenance: 'Zustand und Wartung',
        stateOfUse: {
            present: 'Vorhanden',
            missing: 'Fehlend',
            [AssetStateOfUse.Insufficient]: 'Unzureichend',
            [AssetStateOfUse.WorkingAndMaintained]: 'Funktionstüchtig und regelmäßig gewartet',
            [AssetStateOfUse.LikeNew]: 'Wie neu',
        },
        stateOfUseGearbox: 'Zustand des Getriebes',
        stateOfUseAirConditioner: 'Zustand der Klimaanlage',
        stateOfUseBreakingSystem: 'Zustand des Bremssystems',
        stateOfUseDrivingOrgans: 'Zustand der Lenkung',
        stateOfUseEngine: 'Zustand des Motors',
        stateOfUsePowerSystem: 'Zustand des Kraftstoffsystems',
        stateOfUseTimingBelt: 'Zustand des Zahnriemens',
        stateOfUseTransmissionDevices: 'Zustand der Übertragung',
        stateOfUseTurbine: 'Zustand der Turbine',
        missingParts: 'Fehlende Teile',
        useType: {
            base: 'Nutzungsart',
            [AssetUseType.OwnUse]: 'Eigennutzung',
            [AssetUseType.ThirdPartRent]: 'Drittvermietung',
            [AssetUseType.Line]: 'Linie',
        },
        enginePosition: 'Motorposition',
        position: {
            [AssetPartPosition.Rear]: 'Hinten',
            [AssetPartPosition.Central]: 'Zentral',
            [AssetPartPosition.Frontal]: 'Vorne',
        },
        length: 'Länge',
        height: 'Höhe',
        width: 'Breite',
        vin: 'Fahrgestellnummer',
        converter: 'Wandler',
        visitDate: {
            base: 'Kann besichtigt werden',
            fromTo: 'am {date} von {fromTime} bis {toTime}',
        },
        inspectionExpiryDate: 'Inspektionsablaufdatum',
        doors: {
            base: 'Türen',
            [AssetDoors.Double]: '2',
            [AssetDoors.DoubleDouble]: '2+2',
            [AssetDoors.DoubleDoubleDouble]: '2+2+2',
            [AssetDoors.DoubleDoubleSingle]: '2+2+1',
            [AssetDoors.DoubleSingleSingle]: '2+1+1',
            [AssetDoors.Single]: '1',
            [AssetDoors.SingleDouble]: '1+2',
            [AssetDoors.SingleSingle]: '1+1',
            [AssetDoors.SingleDoubleSingle]: '1+2+1',
            [AssetDoors.SingleSingleSingle]: '1+1+1',
            [AssetDoors.SingleDoubleDouble]: '1+2+2',
        },
        seatUpholstery: {
            base: 'Sitzpolsterung',
            [AssetSeatUpholstery.Fabric]: 'Stoff',
            [AssetSeatUpholstery.Leather]: 'Leder',
            [AssetSeatUpholstery.Plastic]: 'Kunststoff',
            [AssetSeatUpholstery.FabricLeather]: 'Stoff, Leder',
        },
        seats: 'Sitzplätze',
        totalCapacity: 'Gesamte Fahrgastkapazität',
        seatsFromS1: 'Sitze einschließlich Fahrersitz aus dem Fahrzeugschein S1',
        standingPlaces: 'Stehplätze',
        standingPlacesFromS2: 'Stehplätze laut Zulassungsbescheinigung S2',
        foldingSeats: 'Klappsitze',
        wheelchairPlaces: 'Rollstuhlplätze',
        companionSeats: 'Sitze für Begleitpersonen',
        wheelchairRamp: {
            base: 'Rollstuhlrampe',
            [AssetWheelchairRamp.Absent]: 'Nicht vorhanden',
            [AssetWheelchairRamp.Electric]: 'Elektrisch',
            [AssetWheelchairRamp.Manual]: 'Manuell',
            [AssetWheelchairRamp.Electropneumatic]: 'Elektropneumatisch',
        },
        luggageCapacity: 'Gepäckraumvolumen',
        passThroughLuggageCompartment: 'Durchgangskofferraum',
        hatBox: 'Ablagefach',
        transmission: 'Getriebe',
        gearboxType: {
            base: 'Getriebeart',
            [AssetGearboxType.Auto]: 'Automatik',
            [AssetGearboxType.Manual]: 'Manuell',
            [AssetGearboxType.SemiAuto]: 'Halbautomatisch',
        },
        gearboxBrand: 'Getriebemarke',
        gearboxModel: 'Getriebemodell',
        glasses: {
            base: 'Fenster',
            [Glasses.Single]: 'Einfachverglasung',
            [Glasses.Double]: 'Doppelverglasung',
        },
        openableWindows: 'Funktionsfähige Fenster',
        engine: 'Motor',
        engineBrand: 'Motormarke',
        engineModel: 'Motorenmodell',
        hp: 'PS',
        kw: 'Motorleistung',
        displacement: 'Hubraum',
        cylinders: 'Anzahl der Zylinder',
        fuel: {
            base: 'Kraftstoff',
            [AssetFuel.CNG]: 'CNG',
            [AssetFuel.Electric]: 'Elektrisch',
            [AssetFuel.GPL]: 'LPG',
            [AssetFuel.Diesel]: 'Diesel',
            [AssetFuel.Gasoline]: 'Benzin',
            [AssetFuel.Hybrid]: 'Hybrid',
            [AssetFuel.Hydrogen]: 'Wasserstoff',
        },
        fuelTankCapacity: 'Kraftstofftankvolumen',
        gasTanks: 'Gastanks',
        gasTanksMaterial: {
            base: 'Kraftstofftankmaterial',
            [GasTanksMaterials.Aluminum]: 'Aluminium',
            [GasTanksMaterials.Composite]: 'Verbundwerkstoff',
            [GasTanksMaterials.Steel]: 'Stahl',
        },
        batteryPower: 'Batterieleistung',
        chargingType: 'Ladeart',
        axlesTires: 'Achsen und Reifen',
        wheelSize: 'Abmessungen des Reifens',
        wheels: 'Anzahl der Reifen (Einzel- oder Zwillingsbereifung)',
        axles: 'Achsen',
        wheelsSizeAxle1: 'Abmessungen des Reifens für Achse 1',
        wheelsSizeAxle2: 'Abmessungen des Reifens für Achse 2',
        wheelsSizeAxle3: 'Abmessungen des Reifens für Achse 3',
        alloyWheels: 'Aluminiumfelgen',
        spareWheel: 'Reserverad',
        firePreventionSystem: 'Brandmeldeanlage',
        firePreventionSystemBrand: 'Marke der Brandmeldeanlage',
        abs: 'ABS',
        asr: 'ASR',
        retarder: 'Retarder',
        breathalyzer: 'Alkoholtester',
        tachograph: { base: 'Tachograph', [AssetTachograph.Absent]: 'Abwesend', [AssetTachograph.Analog]: 'Analog', [AssetTachograph.Digital]: 'Digital' },
        indicatorSigns: 'Zielschilder',
        indicatorSignsBrand: 'Marke der Zielschilder',
        accessories: 'Zubehör',
        cruiseControl: {
            base: 'Tempomat',
            [CruiseControlType.No]: 'Nein',
            [CruiseControlType.Adaptive]: 'Adaptiv',
            [CruiseControlType.Standard]: 'Standard',
        },
        deckType: {
            base: 'Bodentyp',
            [DeckType.LowEntry]: 'Niedriger Eingang',
            [DeckType.LowFloor]: 'Niederflur',
            [DeckType.NormalFloor]: 'Hochflur',
        },
        acDriver: 'Fahrerklimaanlage',
        driverBerth: 'Fahrer-Koje',
        wifi: 'WLAN',
        cctv: 'Videoüberwachung (CCTV)',
        internalCameras: 'Interne Kameras',
        externalCameras: 'Externe Kameras',
        retrocamera: 'Rückfahrkamera',
        microphone: 'Mikrofon',
        cdPlayer: 'CD-Spieler',
        dvdPlayer: 'DVD-Spieler',
        usbPlayer: 'Radio-USB-Spieler',
        heater: 'Heizung',
        preHeater: 'Vorheizer',
        kitchen: 'Küche',
        coffeeMachine: 'Kaffeemaschine',
        refrigerator: 'Kühlschrank',
        toilet: 'Toilette',
        safetyBelts: 'Sicherheitsgurte',
        acPassengers: 'Passagierklimaanlage',
        curtains: 'Vorhänge',
        seatPocket: 'Sitztasche',
        footRest: 'Fußstütze',
        handRest: 'Handauflage',
        mp3: 'MP3',
        monitors: 'Monitor',
        seatsMonitor: 'Sitzmonitor',
        seatsUsbPorts: 'USB-Anschluss an den Sitzen',
        socket220: 'Fahrer-Elektrosteckdose',
        seatsSocket220: 'Beifahrersitz-Steckdose 220 V',
        seatsRadio: 'Radio an den Sitzen',
        extendableSeats: 'Ausziehbare Sitze',
        recliningSeats: 'Liegesitze',
        coffeeTables: 'Couchtische',
    },
    auction: {
        countdown: {
            startsIn: 'Beginnt in',
            endsIn: 'Endet in',
            days: 'd',
            hours: 'h',
            minutes: 'm',
            seconds: 's',
        },
        bail: {
            title: 'Bindung der Kaution an die Auktionsteilnahme',
            description: 'Um an dieser Auktion teilnehmen zu können, müssen Sie die Kaution mit einer gültigen Zahlungsmethode hinterlegen.',
            amount: 'KAUTION',
            note: 'Die Kaution wird am Ende der Auktion freigegeben, wenn Sie nicht zu den Höchstbietenden gehören.',
            subnote: 'Die Kautionen sind nicht für alle Assets gleich und können je nach Auktionsbasis variieren.',
            error: 'Bei der Verarbeitung der Transaktion ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support.',
            submit: 'Kaution binden',
        },
        bid: {
            title: 'Werde jetzt der Höchstbietende',
            description: 'Denke daran, dass das Einzel- oder Höchstgebot bindend ist.',
            autoBidEditTitle: 'Aktualisiere dein automatisches Gebot',
            autoBidEditDescription: 'Denke daran, dass das Höchstgebot bindend ist.',
            activeAutoBid: 'Automatisches Gebot aktiv bis',
            actualBid: 'Aktuelles Gebot',
            autoBidToggle: 'Automatisches Bieten ({0})',
            autoBidLink: 'was ist das?',
            yourBid: 'Dein Gebot',
            yourMaxBid: 'Dein Höchstgebot',
            restoreMinBid: 'Zurücksetzen',
            confirmSingleBid: 'Gebot abgeben',
            confirmAutoBid: 'Automatisches Gebot aktivieren',
            confirmAutoBidUpdate: 'Automatisches Gebot aktualisieren',
            cancel: 'Abbrechen',
            success: 'Gebot erfolgreich abgegeben!',
            outbid: 'Ein anderer Benutzer hat dein Gebot überboten',
            expiredError: {
                title: 'Die Auktion ist geschlossen',
                description: 'Es können keine weiteren Gebote abgegeben werden.',
            },
        },
        bidButton: 'Geben Sie ein Gebot ab',
        bidButtonActiveAutoBid: 'Automatisches Gebot ändern',
        auctionPanel: {
            reservePrice: 'Mindestpreis',
            startingPrice: 'Startpreis',
            current: 'Aktuell',
            wonAuction: 'Auktion gewonnen!',
            state: {
                [AuctionState.Closed]: 'Geschlossene Auktion',
                [AuctionState.Canceled]: 'Abgebrochene Auktion',
                [AuctionState.Draft]: 'Entwurfsauktion',
            },
            maxBidder: 'Ihr Gebot ist das höchste!',
            outBid: 'Sie wurden überboten!',
            negotiationInProgress: 'Verhandlung läuft',
            feeNotice: '+ Gebühren ({0})',
            feeNoticeLink: 'was sind sie?',
            priceNotAvailable: 'N/A',
            bidNotice: {
                reservePriceNotReached: {
                    base: 'Mindestpreis {0}',
                    notReached: 'noch nicht erreicht',
                },
                notScrapyard: 'Die Auktion ist auf Demontagebetriebe, die im Abfallwirtschaftsregister in Kat. 5 eingetragen sind, und für Verwalter des C.E.R.-Codes 16.01.04 beschränkt',
                sameCompany: {
                    [AuctionState.Running]: 'Sie können nicht teilnehmen, weil das Fahrzeug bereits Ihrem Unternehmen gehört',
                    base: 'Das Fahrzeug gehört bereits Ihrem Unternehmen',
                },
                isAdmin: 'Sie können nicht teilnehmen, weil Ihr Benutzer die Admin-Rolle hat',
                unapprovedCompany: 'Sie können keine Gebote abgeben, da wir die Dokumente Ihres Unternehmens überprüfen.',
            },
        },
        needSupport: 'Sie benötigen Unterstützung?',
        contactUs: 'Wenden Sie sich an den Fleequid-Kundendienst',
    },
    auctions: {
        page: {
            asset: {
                [AssetType.Bus]: 'Bus | Busse',
            },
            title: 'Entdecken Sie alle {title} auf Fleequid',
            results: '{total} sichtbare Auktionen',
            emptyStateTitle: 'Keine Ergebnisse',
            emptyStateText: 'Die angewendeten Filter haben keine Ergebnisse geliefert.',
            resetFilters: 'Filter entfernen',
        },
        filters: {
            applied: 'Angewandte Filter',
            button: {
                label: 'Auktionsliste filtern',
            },
            modal: {
                title: 'Auktionsliste filtern',
                back: 'Zurück zu den Ergebnissen',
            },
            label: 'Filter',
            class: 'Fahrzeugklasse',
            brand: 'Marke, Modell, Version',
            brandFilter: 'Marke',
            brandFilterAnyOption: '',
            modelFilter: 'Modell',
            modelFilterAnyOption: '',
            versionFilter: 'Version',
            versionFilterAnyOption: '',
            antiPollution: 'Schadstoffklasse',
            registration: 'Anmeldung',
            yearFrom: 'Ab (year)',
            yearTo: 'Bis (year)',
            yearAnyOption: 'Alle Jahre',
            kilometers: 'Kilometerstand',
            kmFrom: 'Von',
            kmTo: 'Bis',
            kmAnyOption: 'Beliebiger Kilometerstand',
            state: {
                title: 'Auktionsstatus',
                all: 'Alle Status',
                [AuctionState.Pending]: 'Noch nicht gestartete Auktionen',
                [AuctionState.Running]: 'Laufende Auktionen',
                [AuctionState.Closed]: 'Geschlossene Auktionen',
            },
            scrapping: {
                title: 'Verschrottungsbeschränkungen',
                help: 'Was sind Verschrottungsbeschränkungen?',
                all: 'Alle Fahrzeuge',
                [RequireScrappingFilter.requireScrapping]: 'Mit Verschrottungsbeschränkungen',
                [RequireScrappingFilter.doNotRequireScrapping]: 'Ohne Verschrottungsbeschränkungen',
            },
            fuel: 'Kraftstoff',
            length: 'Länge',
            lengthFrom: 'Von',
            lengthTo: 'Bis',
            lengthAnyOption: 'Beliebige Länge',
            empty: 'Keine Filter angewandt.',
        },
        sorting: {
            title: 'Sortieren nach',
            yearAsc: 'Älteste Registrierung',
            yearDesc: 'Neueste Registrierung',
            priceAsc: 'Günstigste',
            priceDesc: 'Teuerste',
            kmAsc: 'Aufsteigender Kilometerstand',
            kmDesc: 'Absteigender Kilometerstand',
            defaultByState: {
                all: 'Standardreihenfolge',
                [AuctionState.Running]: 'Baldiges Ende',
                [AuctionState.Closed]: 'Zuletzt beendet',
                [AuctionState.Pending]: 'Baldiger Start',
            },
        },
        appliedFilters: {
            from: 'ab: {0}',
            to: 'bis: {0}',
            reset: 'Filter zurücksetzen',
            appliedFiltersCounter: '{count} aktiver Filter | {count} aktive Filter',
        },
    },
    homepage: {
        link: 'Startseite',
        hero: {
            title: 'Es gibt keine schweren Fahrzeuge',
            subtitle: 'Kaufen oder verkaufen Sie Ihren Bus einfach, schnell und transparent bei unseren Online-Auktionen',
            buttonLabel: 'An Auktionen teilnehmen',
        },
        whatIsFleequid: {
            title: 'Was ist Fleequid?',
            description: 'Fleequid ist die neue Art, Fahrzeuge für Ihr Unternehmen zu versteigern und zu kaufen',
            section: {
                simplicity: {
                    title: 'Einfachheit',
                    description: 'Ein linearer und intuitiver Prozess, der endlose Verhandlungen überflüssig macht',
                },
                speed: {
                    title: 'Geschwindigkeit',
                    description: 'Sie können Ihren neuen Bus in 7 Tagen kaufen',
                },
                transparency: {
                    title: 'Transparenz',
                    description: 'Klare Preise, detaillierte Bilder, engagierte Unterstützung',
                },
            },
        },
        vision: {
            caption: 'Unsere Vision',
            title: 'Wir glauben an die Vorteile von Gebrauchtfahrzeugen. Unser Ziel ist es,Unternehmen beim Kauf und Verkauf von Firmenfahrzeugen zu unterstützen, Zeit und Ressourcen zu sparen und aus einem Problem eine Chance zu machen.',
            highlight: 'Vergessen Sie Risiko und Unsicherheit: Wir machen es Ihnen leicht, den Wert Ihrer Assets zu steigern.',
        },
        howItWorks: {
            title: 'Wie es funktioniert',
            description: 'Unsere Plattform ist einfach und intuitiv, damit Sie Ihre Busflotte einfach verwalten können',
            signUp: {
                title: 'Bei Fleequid anmelden',
                create: 'Erstellen Sie ein {account} mit Ihren Unternehmensdaten',
                account: 'Neues Konto',
                verify: 'Daten verifizieren und Website {browse}',
                browse: 'Suche starten',
            },
            join: {
                title: 'An Auktionen teilnehmen',
                choose: 'Wählen Sie einen {bus} aus der Liste',
                bus: 'Bus',
                add: 'Fügen Sie eine gültige {payment} hinzu, um Gebote abzugeben',
                payment: 'Zahlungsmethode',
                follow: 'Folgen Sie der Auktion und {win}',
                win: 'gewinnen Sie Ihre Lieblingsbusse',
            },
            sell: {
                title: 'Verkaufen Sie Ihre Firmenbusse',
                letUsKnow: 'Teilen Sie uns alle Details zum {bus} mit',
                bus: 'Bus zum Verkauf',
                choose: 'Wählen Sie einen {price} für Ihren Bus',
                price: 'Mindestpreis', // TODO
                wait: 'Warten Sie, bis die Auktion endet, um {confirm}',
                confirm: 'den Verkauf zu bestätigen',
            },
        },
        whyFleequid: {
            title: 'Warum Fleequid?',
            description: 'Wir wollen Ihr zuverlässiger Partner für das Management Ihrer Flotte sein',
            section: {
                euReach: {
                    title: 'Europäische Reichweite',
                    description: 'Verkürzung der Verhandlungszeit und Vereinfachung von Verwaltung und Papierkram',
                },
                unifiedBilling: {
                    title: 'Einheitliche Abrechnung',
                    description: 'Profitieren Sie von einer effizienten Plattform und einem einzigen Firmennamen für die Rechnungsstellung.',
                },
                easyAuctions: {
                    title: 'Einfache und bequeme Auktionen',
                    description: "Thanks to our pre- and post-purchase support service, participating in auctions will be child's play.",
                },
                operationalEfficiency: {
                    title: 'Operative Effizienz',
                    description: 'Verkürzung der Verhandlungszeit und Vereinfachung von Verwaltung und Papierkram',
                },
                sell: {
                    title: 'Verkaufen Sie Ihre Fahrzeuge',
                    description: 'Fleequid unterstützt Sie bei der Festlegung des Verkaufspreises und der Erstellung des Fotodossiers.',
                    cta: 'Verkaufen Sie auf Fleequid',
                },
            },
        },
        startToday: {
            title: 'Starten Sie noch heute auf Fleequid',
            description: 'Sehen Sie sich alle verfügbaren Auktionen an und machen Sie sich bereit für die besten Produkte',
            cta: 'An Auktionen teilnehmen',
        },
    },
    faq: {
        title: 'Häufig gestellte Fragen',
        start: {
            base: 'Erste Schritte',
            participate: {
                q: 'Wie kann ich an einer Online-Auktion teilnehmen?',
                a: 'Um an Online-Auktionen auf Fleequid teilnehmen zu können, müssen Sie sich bei {0} registrieren, um Ihre Unternehmensdaten anzugeben und die erforderlichen Dokumente einzureichen. Danach können Sie die laufenden Auktionen einsehen und ein oder mehrere Gebote für den Kauf eines Fahrzeugs abgeben. Auktionen werden elektronisch durchgeführt und Sie können solange bieten, bis die Auktion endet. Die Auktion endet, wenn der Benutzer mit dem höchsten Gebot den Zuschlag für das Fahrzeug erhält, sofern das Gebot mindestens dem Mindestpreis entspricht, oder wenn der Verkäufer ein Gebot akzeptiert, das unter dem Mindestpreis liegt. ',
                link: 'https://fleequid.com/en/signup',
            },
            reservePrice: {
                q: 'Wie hoch ist der Mindestpreis',
                a: 'Der Mindestpreis ist der Betrag, der mindestens erreicht werden muss, um die Auktion erfolgreich abzuschließen. Wird dieser Betrag während der Auktion nicht erreicht, liegt es im Ermessen des Verkäufers zu entscheiden, ob er bereit ist, das höchste eingegangene Gebot anzunehmen. Der Mindestpreis ist ein höherer Betrag als das Startgebot: Er wird vom Verkäufer vor Beginn der Auktion festgelegt und ist für die Öffentlichkeit nicht sichtbar, die nur das bis zu diesem Zeitpunkt abgegebene Höchstgebot kennt.',
            },
            countries: {
                q: 'In welchen Ländern kann man Busse über Fleequid kaufen und verkaufen?',
                a: 'Fleequid ist in Italien, in der Europäischen Union und in mehreren Nicht-EU-Ländern tätig und hält sich an die Vorschriften der jeweiligen Länder. Wenn Sie Zweifel haben, ob Sie Fleequid in Ihrem Land verwenden können, wenden Sie sich bitte an unseren Kundendienst unter {0}.',
            },
            bureaucracy: {
                q: 'Wer ist für die Eigentumsübertragung, die Fahrzeugzulassung und die Dokumentation zuständig?',
                a: "Fleequid will communicate to seller and buyer how to handle documentation aspects and payments related to the property transfer, depending on the specifics of the deal (between Italian companies, EU, and non-EU) and on the platform's role in the sales process. However, registration of the vehicle in the country of destination is the responsibility of the buyer.",
            },
            toBeScrapped: {
                q: 'Was sind Fahrzeuge, die für die Verschrottung bestimmt sind?',
                a: 'Fahrzeuge, die für die Verschrottung bestimmt sind, sind Fahrzeuge, die nicht mehr für den Verkehr und die Personenbeförderung geeignet sind und die gemäß den geltenden Gesetzen oder Vorschriften einer Verwertung und teilweisen Wiederverwendung ihrer Bauteile als Ersatzteile unterzogen werden müssen. Die Teilnahme an Auktionen für diese Fahrzeuge ist nur für Demontagebetriebe, die im Abfallwirtschaftsregister in Kategorie 5 eingetragen sind, und für Verwalter des C.E.R.-Codes 16.01.04 zulässig. Auktionen für zur Verschrottung bestimmte Fahrzeuge sind derzeit nur in Italien verfügbar.',
            },
            vies: {
                q: 'Was ist das MIAS? Warum ist es obligatorisch, darin eingetragen zu sein?',
                a: 'MIAS ist eine Suchmaschine (keine Datenbank), die von der Europäischen Kommission eingerichtet wurde, um den Handel zwischen Unternehmen in der EU zu erleichtern. Es handelt sich um ein elektronisches Instrument zur Validierung der Umsatzsteuer-Identifikationsnummern von in der Europäischen Union registrierten Wirtschaftsbeteiligten für grenzüberschreitende Waren- und Dienstleistungstransaktionen. Aus diesem Grund müssen europäische Unternehmen, die auf Fleequid handeln wollen, bei MIAS registriert sein.',
            },
            coc: {
                q: 'Was ist ein COC?',
                a: 'Das COC, oder Konformitätsbescheinigung, ist ein Dokument, das die Übereinstimmung eines Fahrzeugs mit bestimmten technischen und Sicherheitsstandards bestätigt, die von den europäischen Vorschriften festgelegt wurden. Dieses Dokument enthält verschiedene Informationen über das Fahrzeug, einschließlich seiner technischen Spezifikationen, Fahrgestellnummer, Emissionsdaten, Zulassungsklasse und die Sicherheitsstandards, denen das Fahrzeug entspricht. Es wird benötigt, um ein Fahrzeug in einem anderen Land innerhalb der Europäischen Union zuzulassen, da der Besitz dieses Dokuments zusätzliche technische Überprüfungen überflüssig macht. Das Fehlen des COC beim Verkauf könnte nicht nur die Zulassung verhindern, sondern den Verkäufer möglicherweise auch Sanktionen aussetzen.',
            },
        },
        sell: {
            base: 'So funktioniert der Verkauf',
            selling: {
                q: 'Wie verkaufe ich auf Fleequid?',
                a: 'Wenn Sie einen Bus verkaufen möchten, wenden Sie sich bitte an unseren Kundendienst unter {0} oder füllen Sie das Formular unter {1} aus und geben Sie Informationen über das Fahrzeug an, das Sie verkaufen möchten.  Außerdem müssen Sie Ihr Unternehmen als Verkäufer auf der Plattform registrieren.',
            },

            startingPrice: {
                q: 'Wie wird der Startpreis festgelegt?',
                a: 'Der Startpreis ist der Mindestpreis für das Fahrzeug, zu dem potenzielle Käufer auf dem Marktplatz Gebote oder Gegengebote abgeben können. Der Startpreis wird von Fleequid auf der Grundlage seiner technischen Bewertung des Fahrzeugs berechnet und muss unter dem vom Verkäufer festgelegten Mindestpreis liegen.',
            },
            delivery: {
                q: 'Wie wird das Fahrzeug geliefert?',
                a: 'Nach Erhalt der Zahlung muss der Verkäufer dem Käufer ermöglichen, das Fahrzeug abzuholen; der Käufer muss die Abholung unverzüglich durchführen, die innerhalb von zehn Tagen ab dem Datum erfolgen muss, an dem Fleequid dem Käufer mitteilt, wann das Fahrzeug abgemeldet wurde, damit dieser die Abholung organisieren kann. Für jeden Tag der Verzögerung über die oben genannte Frist hinaus erhebt Fleequid eine Strafgebühr.',
            },
            howLong: {
                q: 'Wie lange dauert es, bis der Bus verkauft wird?',
                a: 'Wenn ein Fahrzeug auf die Plattform hochgeladen wird, teilt Fleequid dem Verkäufer das Start- und Enddatum der Auktion sowie die Informationen zur Sichtbarkeit vor der Auktion mit. Diese Daten werden von Fleequid auf der Grundlage der Eigenschaften des zu verkaufenden Fahrzeugs festgelegt. Kommt der Verkauf nicht zustande, hat Fleequid das Recht, bis zu drei weitere Auktionen innerhalb von insgesamt 30 Tagen durchzuführen.',
            },
            expiredInspection: {
                q: 'Ist es möglich, ein Fahrzeug mit abgelaufener Inspektion zu kaufen?',
                a: 'Wenn Ihr Unternehmen außerhalb Italiens tätig ist, können Sie keine Fahrzeuge kaufen, die sich in Italien befinden und mit "abgelaufener Inspektion" gekennzeichnet sind. Fahrzeuge, die in Italien zugelassen sind und deren Inspektion abgelaufen ist, können nur innerhalb des Landes verkauft werden. Um ein solches Fahrzeug in andere Länder zu exportieren, muss es zuerst aus dem italienischen öffentlichen Fahrzeugregister (PRA) abgemeldet werden, und eine gültige Inspektion ist Voraussetzung für diese Abmeldung.',
            },
        },
        buy: {
            base: 'So kaufen Sie',
            inspection: {
                q: 'Ist es möglich, Fahrzeuge vor der Auktion zu besichtigen?',
                a: 'Der Verkäufer kann entscheiden, ob er die Möglichkeit einer persönlichen Besichtigung des Fahrzeugs anbietet. Wenn dies erlaubt ist, legt der Verkäufer einen bestimmten Tag für Besichtigungen und Inspektionen des Fahrzeugs direkt am Standort des Verkäufers fest.',
            },
            claims: {
                q: 'Wie gehen Sie mit versteckten Mängeln und Beschwerden um?',
                a: 'Fleequid fungiert als Vermittler zwischen Verkäufer und Käufer und ist als solcher nicht verantwortlich für versteckte oder sichtbare Fehler, Mängel, Abnutzung, Unregelmäßigkeiten, Unbrauchbarkeit oder Fehler oder Ungenauigkeiten in den Erklärungen des Verkäufers. Mit der Veröffentlichung seiner Auktion auf Fleequid verpflichtet sich der Verkäufer, transparent zu sein und genaue Informationen über den Zustand des Fahrzeugs zur Verfügung zu stellen; gleichzeitig verpflichtet sich der Käufer, Abweichungen innerhalb eines Monats nach Abholung bei versteckten Mängeln des Fahrzeugs rechtzeitig zu melden (3 Tage bei offensichtlichen Mängeln). Fleequid steht den Käufern bei der Bearbeitung von Beschwerden zur Verfügung und bietet spezielle Bedingungen für die Bearbeitung von Beschwerden an.',
            },
            paymentMethods: {
                q: 'Welche Zahlungsmethoden werden akzeptiert?',
                a: 'Fleequid akzeptiert Banküberweisungen und Kreditkartenzahlungen. Genauer gesagt muss nur die Kaution per Kreditkarte bezahlt werden, der Kauf des Fahrzeugs, die Zahlung der Provision und eventuelle Zusatzkosten müssen per Banküberweisung bezahlt werden. ',
            },
            howPaymentWorks: {
                q: 'Wie funktioniert die Bezahlung?',
                a: 'Der Nutzer, der die Auktion gewinnt, muss einen Betrag zahlen, der den Auktionspreis, die Verkaufsgebühr für Fleequid und alle anderen vereinbarten Gebühren umfasst (siehe Allgemeine Geschäftsbedingungen für weitere Details). Der Käufer muss den fälligen Betrag per Banküberweisung spätestens drei Tage nach Erhalt der entsprechenden Rechnung bezahlen. Teilzahlungen sind nicht gestattet.',
            },
            deposit: {
                q: 'Wie funktioniert die Kaution?',
                a: 'Die Kaution ist der Geldbetrag, den der Benutzer Fleequid bei der Abgabe eines Gebotes in der Auktion als Garantie für die Ernsthaftigkeit des Gebotes selbst zahlt. Am Ende der Auktion werden alle Kautionen freigegeben, mit Ausnahme der Kautionen der Gewinner des ersten und zweiten Platzes. Wenn der Gewinner den Kauf des Fahrzeugs per Banküberweisung in der vereinbarten Höhe abschließt (spätestens 3 Tage nach Erhalt der entsprechenden Rechnung), werden sowohl seine Kaution als auch die des Zweitbieters freigegeben. Wenn der Gewinner die Zahlung nicht innerhalb der vereinbarten Frist vornimmt, verliert er sowohl den Bus als auch die Kaution. In diesem Fall wird der Zweitplatzierte automatisch zum neuen Auktionsgewinner und die Kaution wird durch den Kauf des Fahrzeugs per Banküberweisung zurückerstattet.',
            },
            autoBid: {
                q: 'Wie funktioniert das automatische Bieten?',
                a1: 'Das automatische Bieten ermöglicht es dir, ein maximales Gebot festzulegen, das du für die Auktion bereit bist zu bieten (ohne Gebühren).',
                a2: 'Wenn du es aktivierst, gibt das System sofort das niedrigste gültige Gebot ab, unter Berücksichtigung des Mindestpreises und der aktuellen Gebote anderer Nutzer. Wenn anschließend andere Nutzer Gebote abgeben, erhöht das System automatisch dein Gebot bis zu dem von dir festgelegten Höchstbetrag, nach dessen Erreichen das automatische Bieten deaktiviert wird.',
                a3: 'Das automatische Bieten versucht auch, den Mindestpreis zu erreichen, um deine Chancen auf den Gewinn der Auktion zu maximieren.',
                a4: 'Wenn mehrere Nutzer das automatische Bieten mit dem gleichen Höchstbetrag aktivieren, wird für jeden Nutzer ein Gebot in Höhe des Höchstbetrags abgegeben, aber nur das des Nutzers, der es zuerst aktiviert hat, wird als gewinnend betrachtet. Denke daran, dass alle abgegebenen Gebote bindend sind, auch wenn sie nicht gewinnen.',
                a5: 'Bitte beachte, dass du ein aktives automatisches Gebot nicht stornieren kannst, sondern nur den Höchstbetrag erhöhen kannst.',
            },
        },
        contactUs: 'Wir hoffen, dass Sie auf dieser Seite alle Antworten auf Ihre Fragen gefunden haben. Falls nicht, helfen wir Ihnen gerne weiter. Rufen Sie uns an unter {0} oder schreiben Sie uns an {1}.',
    },
    footer: {
        cookiePolicy: {
            base: 'Cookie-Richtlinie',
            changeConsent: 'Ändern Sie Ihre Zustimmung',
            revokeConsent: 'Widerrufen Sie Ihre Zustimmung',
        },
        explore: 'Entdecken',
        legal: 'Rechtliche Seiten',
        support: 'Benötigen Sie Unterstützung?',
        vat: 'Umsatzsteuer-Identifikationsnummer',
        backTop: 'Zum Seitenanfang',
    },
    menu: {
        buy: 'Kaufen',
        sell: 'Verkaufen',
        faq: 'FAQ',
    },
    mktg: {
        whyFleequid: {
            title: 'Warum Fleequid?',
            deal: {
                title: 'Einfache Geschäfte',
                description: 'Technologieplattform statt Verhandlungen mit unentschlossenen Gesprächspartnern',
            },
            processes: {
                title: 'Schnelle Prozesse',
                description: 'Fleequid spart Ihrem Team Zeit und Energie',
            },
            support: {
                title: 'Eine transparente Unterstützung',
                description: 'Von der Anmeldung bis zur Auslieferung des Fahrzeugs ist alles klar',
            },
        },
    },
    notifications: {
        goToAuction: 'Zur Auktion gehen',
        success: 'Erfolg',
        warning: 'Warnung',
        error: 'Fehler',
        genericError: 'Etwas ist schiefgelaufen. Bitte versuchen Sie es später erneut.',
        tryAgain: 'Bitte versuchen Sie es noch einmal',
        userOutBid: {
            title: 'Ihr Gebot wurde überboten!',
            description: 'Ihr Gebot von {prevBidAmount} für {subject} wurde überboten, der neue aktuelle Preis beträgt {currentBidAmount}.',
            inAuction: 'diese Auktion',
            linkedAuction: '{brand} {model} {version}',
        },
        userBailAuthorized: {
            title: 'Sie sind zur Teilnahme an der Auktion berechtigt!',
            description: 'Sie wurden zur Teilnahme an {context} berechtigt.',
            inAuction: 'Sie wurden autorisiert, an dieser Auktion teilzunehmen',
            linkedAuction: 'Sie wurden autorisiert, an der Auktion für das Fahrzeug {brand}, {model} teilzunehmen',
        },
        userMaxBidderRestored: {
            title: 'Sie sind der Höchstbietende!',
            description: 'Ihr Angebot von {prevBidAmount} für {subject} ist jetzt das höchste!',
            inAuction: 'diese Auktion',
            linkedAuction: '{brand} {model} {version}',
        },
        auctionWon: {
            title: 'Auktion beendet - Sie haben gewonnen!',
            description: 'Wir freuen uns, Ihnen mitteilen zu können, dass Sie {subject} mit Ihrem Gebot von {currentPrice} gewonnen haben. {reserve}',
            inAuction: 'diese Auktion',
            linkedAuction: 'die Auktion für {brand} {model} {version}',
            reservePriceNotReached: 'Leider wurde der Mindestpreis nicht erreicht. Wir werden den Verkäufer kontaktieren, um Ihr Angebot vorzuschlagen und zu sehen, ob er bereit ist, den Verkauf abzuschließen. Wir halten Sie über alle Entwicklungen auf dem Laufenden.',
        },
    },
    privacy: {
        link: 'Datenschutzerklärung',
    },
    seo: {
        auction: {
            title: '{asset} {brand} {model} {version} {vin} bei einer Auktion',
            description: 'Kaufen Sie {brand} {model} {version}, einen gebrauchten {asset} auf Fleequid.com: Nehmen Sie an der B2B-Auktion teil und geben Sie ein Gebot für das {asset} {vin} von der Marke {brand}, Modell {model} und Version {version}, ab.',
            ogTitle: '{asset} {brand} {model} {version}',
            ogDescription: '{year}, {euroNorm}, {kilometers}: Nehmen Sie an der B2B-Auktion teil und geben Sie ein Gebot für dieses gebrauchte {asset} direkt online auf Fleequid.com ab.',
        },
        auctions: {
            title: 'Gebrauchte {0} zum Verkauf',
            description: 'Kaufen Sie gebrauchte {0} online bei Fleequid.com: Nehmen Sie einfach an den B2B-Auktionen der besten gebrauchten {1} teil.',
        },
        cookiePolicy: {
            title: 'Cookie-Richtlinie',
            description: 'Erfahren Sie mehr über die Cookie-Verwaltung auf dem Fleequid-Portal: Entdecken Sie, wie wir Cookies verwenden, um Ihr Surferlebnis zu verbessern',
        },
        homepage: {
            title: 'Busse zum Verkauf',
            description: 'Kaufen oder verkaufen Sie Busse und Nutzfahrzeuge auf Auktionen: Treten Sie Fleequid bei und nehmen Sie mühelos an erstklassigen B2B-Auktionen für Nutzfahrzeuge teil',
        },
        privacyPolicy: {
            title: 'Datenschutzerklärung',
            description: 'Datenschutzerklärung für das Fleequid-Portal: Wir erklären klar und deutlich, wie wir mit Ihren Browsing-Daten und all Ihren Informationen umgehen.',
        },
        signup: {
            title: 'Online-Fahrzeugauktionen: Registrierung',
            description: 'Registrieren Sie sich jetzt bei Fleequid, um einfach und bequem an Auktionen für neue und gebrauchte Nutzfahrzeuge, Busse und Reisebusse teilzunehmen.',
            confirm: {
                title: 'E-Mail-Verifizierung',
            },
        },
        termsAndConditions: {
            title: 'Allgemeine Geschäftsbedingungen',
            description: 'Allgemeine Geschäftsbedingungen für den Kauf und Verkauf auf Fleequid, dem Online-Portal für die Versteigerung und den Kauf von Nutzfahrzeugen und Bussen.',
        },
        pricingSeller: {
            title: 'Preisgestaltung für Verkäufer',
            description: 'Auktionsgebühren auf Fleequid.com: Preisdetails und Details zur Anwendung der Auktionsgebühren.',
        },
        pricingBuyer: {
            title: 'Preisgestaltung für Käufer',
            description: 'Auktionsgebühren auf Fleequid.com: Preisdetails und Details zur Anwendung der Auktionsgebühren.',
        },
    },
    signup: {
        link: 'Registrierung',
        subtitle: 'Geben Sie Ihre Unternehmensinformationen ein und fügen Sie neue Assets zu Ihrer Flotte hinzu.',
        companyRegistration: 'Ihr Unternehmen registrieren',
        companyInfo: 'Informationen zum Unternehmen',
        companyDocuments: 'Dokumente des Unternehmens',
        contactPerson: 'Ansprechpartner im Unternehmen',
        accessCredentials: 'Zugangsdaten',
        fleetHandling: 'Flottenmanagement',
        fields: {
            address: 'Anschrift',
            postCode: 'Postleitzahl',
            city: 'Stadt',
            province: 'Bundesland/Region',
            businessName: 'Firmenname',
            country: 'Land',
            pec: 'Zertifizierte E-Mail-Adresse',
            sdi: 'SDI-ID',
            vatId: {
                base: 'USt-IdNr.',
                warning: 'Die USt-IdNr. scheint falsch zu sein.',
                validationUnavailable: 'Wir konnten die USt-IdNr. für das von Ihnen eingegebene Land nicht überprüfen. Ein Kundenservice-Mitarbeiter wird Ihre Angaben prüfen, sobald Sie den Registrierungsprozess abgeschlossen haben.',
            },
            companyRegistrationReportFile: 'Bericht über die Eintragung des Unternehmens',
            firstname: 'Vorname',
            lastname: 'Nachname',
            phone: 'Telefonnummer',
            type: {
                label: 'Betreibertyp',
                [UserType.Dealer]: 'Händler',
                [UserType.PeopleTransportOperator]: 'Personenkraftverkehrsunternehmer',
                [UserType.Scrapyard]: 'Demontagebetrieb',
                [UserType.Other]: 'Sonstiges',
            },
            email: 'E-Mail-Adresse',
            password: 'Passwort',
            confirmPassword: 'Passwort bestätigen',
            stats: {
                sales: 'Wie viele Busse werden Sie voraussichtlich in {year} verkaufen?',
                purchase: 'Wie viele gebrauchte Busse werden Sie voraussichtlich in {year} kaufen?',
                unknown: "I don't know",
            },
            consent: 'Zustimmung',
            termsConditions: 'Ich habe die {0} gelesen und erkläre mich damit einverstanden.',
            vexatiousClauses: 'Ich habe die folgenden Bedingungen gelesen und akzeptiere sie:',
            privacy: 'Ich habe die {0} gelesen und erkläre mich damit einverstanden.',
        },
        hint: {
            phone: {
                text: 'Nummernformat',
                rule: 'Geben Sie die Telefonnummer im Format +49 1234567890 ein',
            },
        },
        validations: {
            requiredSDIOrPec: 'Geben Sie mindestens einen SDI-Code oder eine PEC-Adresse ein',
            companyRegistration: 'Die ausgewählte Datei ist zu groß. Die maximal zulässige Dateigröße beträgt {size} MB',
        },
        success: {
            title: 'Vielen Dank, dass Sie die Registrierung abgeschlossen haben!',
            p1: 'Ihre Daten wurden erfolgreich empfangen.',
            p2: 'Sie erhalten in Kürze eine Bestätigungs-E-Mail mit einem Link, den Sie anklicken können, um Ihre E-Mail-Adresse zu verifizieren.',
            p3: 'Wir empfehlen Ihnen, auch Ihren Spam-Ordner zu überprüfen, da die E-Mail aufgrund Ihrer Einstellungen möglicherweise als Spam markiert wurde.',
            p4: 'Wir laden Sie ein, die Online-Auktionen zu besuchen und zu sehen, ob es Fahrzeuge gibt, die Sie interessieren. Sie möchten Ihre Busse bei der nächsten Auktion verkaufen? Kontaktieren Sie uns unter {0}.',
            p5: 'Vielen Dank, dass Sie sich für uns entschieden haben!',
        },
        confirm: {
            success: {
                title: 'Ihre E-Mail-Adresse ist jetzt verifiziert!',
                p1: 'Vielen Dank, dass Sie Ihre E-Mail-Adresse {email} verifiziert haben.',
                p2: 'Ihr Konto ist jetzt aktiv und Sie haben vollen Zugriff auf alle unsere Funktionen.',
                p3: 'Wenn Sie Fragen haben oder Hilfe benötigen, wenden Sie sich an unser Support-Team unter {0}. Willkommen an Bord!',
            },
            error: {
                title: 'E-Mail-Verifizierung fehlgeschlagen',
                p1: "We're sorry, but your email verification was unsuccessful. ",
                p2: 'Möglicherweise gab es ein Problem mit dem Verifizierungslink. ',
                p3: 'Bitte versuchen Sie es erneut, indem Sie auf den Verifizierungslink klicken, den wir Ihnen geschickt haben. Wenn das Problem weiterhin besteht, wenden Sie sich an unser Support-Team unter {0}.',
            },
            buyCta: 'An Auktionen teilnehmen',
            hpCta: 'Zurück zur Startseite',
        },
    },
    termsConditions: {
        link: "Terms {'&'} Conditions",
    },
    login: {
        email: 'E-Mail-Adresse',
        password: 'Passwort',
        formSubtitle: 'Geben Sie Ihre Fleequid-Anmeldeinformationen ein, um an den Auktionen teilzunehmen',
        forgotPassword: 'Passwort vergessen?',
        unregistered: "Don't have a Fleequid account?",
        signupNow: 'Jetzt anmelden',
    },
    passwordReset: {
        request: {
            title: 'Ihr Passwort zurücksetzen',
            subTitle: 'Bitte geben Sie Ihre E-Mail-Adresse ein, damit wir Ihnen einen Link zum Zurücksetzen Ihres Passworts zusenden können.',
            submitButton: 'E-Mail senden',
            onSuccessTitle: 'Anfrage akzeptiert',
            onSuccessDescription: 'Sie erhalten eine E-Mail mit dem Verfahren zum Zurücksetzen des Passworts.',
        },
        reset: {
            title: 'Ihr Passwort zurücksetzen',
            description: 'Neues Passwort eingeben. Sobald die Änderung bestätigt wurde, werden alle aktiven Sitzungen ungültig.',
            newPassword: 'Neues Passwort',
            confirmNewPassword: 'Neues Passwort bestätigen',
            submitButton: 'Passwort ändern',
            updateSuccess: 'Das Passwort wurde erfolgreich geändert.',
            tokenExpired: 'Das Token zum Zurücksetzen des Passworts ist abgelaufen.',
            tokenInvalid: 'Dieses Token zum Zurücksetzen des Passworts ist ungültig.',
            startOverDescription: 'Starten Sie den Vorgang zum Zurücksetzen des Passworts erneut, um ein neues Token zu generieren.',
            startOverButton: 'Versuchen Sie es erneut',
        },
    },
    validation: {
        msg: {
            characters: 'Ein oder mehrere ungültige Zeichen',
            email: "Email should resemble user{'@'}domain.ext",
            incomplete: '{field} ist unvollständig',
            invalid: '{field} ungültig',
            invalidBid: 'Der eingegebene Betrag ist ungültig',
            missing: '{field} fehlt',
            max: 'Maximale Länge {count} Zeichen | Maximale Länge {count} Zeichen',
            min: 'Mindestlänge {count} Zeichen | Mindestlänge {count} Zeichen',
            length: 'Die Länge muss genau {count} Zeichen sein | Die Länge muss genau {count} Zeichen sein',
            passwordWeak: 'Das Passwort ist zu schwach',
            passwordMatch: 'Die Passwörter stimmen nicht überein',
            phone: 'Bitte verwenden Sie nur Ziffern, Leerzeichen und das Zeichen „+“',
            recaptcha: 'reCAPTCHA-Validierungsfehler',
            required: '{field} ist ein Pflichtfeld',
        },
        hint: {
            password: {
                text: 'Regeln für ein sicheres Passwort',
                rules: {
                    length: 'Mindestens 8 Zeichen',
                    uppercase: '1 Kleinbuchstabe',
                    lowercase: '1 Großbuchstabe',
                    symbol: '1 Sonderzeichen',
                    number: '1 Zahl',
                },
            },
        },
    },
    profile: {
        title: 'Ihr Profil',
        companyData: {
            readOnlyDisclaimer: 'Die Unternehmensdaten sind schreibgeschützt. Um Änderungen anzufordern, wenden Sie sich an uns unter {0}.',
        },
        userData: {
            saveChanges: 'Neue Daten bestätigen',
            cancelChanges: 'Änderungen abbrechen',
            updateSuccess: 'Personenbezogene Daten korrekt gespeichert',
        },
        changePassword: {
            title: 'Passwort ändern',
            description: 'Wenn Sie Ihr Passwort ändern, werden alle anderen aktiven Sitzungen außer der aktuellen ungültig.',
            currentPassword: 'Aktuelles Passwort',
            newPassword: 'Neues Passwort',
            confirmNewPassword: 'Neues Passwort bestätigen',
            submitButton: 'Passwort ändern',
            updateSuccess: 'Das Passwort wurde erfolgreich geändert.',
        },
        changeEmail: {
            title: 'E-Mail-Adresse ändern',
            description: 'Sie erhalten eine E-Mail mit einem Link zur Bestätigung der neuen E-Mail. Wenn Sie Ihre E-Mail ändern, werden alle Ihre aktiven Sitzungen ungültig.',
            password: 'Aktuelles Passwort',
            email: 'E-Mail-Adresse',
            submitButton: 'E-Mail-Adresse ändern',
            updateSuccess: 'Die E-Mail-Adresse wurde erfolgreich geändert',
        },
    },
    sessionExpired: {
        title: 'Ihre Sitzung ist abgelaufen',
        description: 'Die Sitzung wurde für ungültig erklärt, da die Anmeldeinformationen geändert wurden. Bitte melden Sie sich mit den neuen Anmeldeinformationen an.',
        close: 'Weiter',
    },
    nuxtSiteConfig: {
        description: 'Kaufen oder verkaufen Sie Busse und Nutzfahrzeuge durch Auktionen: Treten Sie Fleequid bei und nehmen Sie mühelos an erstklassigen B2B-Auktionen für Industriefahrzeuge teil.',
    },
};

export default de;
