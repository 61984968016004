import en from './en';

type i18nKeys = keyof typeof en;
type i18nValues = (typeof en)[i18nKeys];

const de: Record<i18nKeys, i18nValues> = {
    currency: {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    },
    integer: {
        style: 'decimal',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    },
    decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    },
    optionalDecimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    },
};

export default de;
