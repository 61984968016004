import en from './en';

import { AssetDoors, AssetEuroNorm, AssetFuel, AssetGearboxType, AssetPartPosition, AssetSeatUpholstery, AssetStateOfUse, AssetTachograph, AssetType, AssetUseType, AssetWheelchairRamp, AuctionState, BackendErrorCode, BusClass, CruiseControlType, DeckType, GasTanksMaterials, Glasses, RequireScrappingFilter, UserType, VehicleCondition } from '~/apiClient';

type i18nKeys = keyof typeof en;
type i18nValues = (typeof en)[i18nKeys];

const pl: Record<i18nKeys, i18nValues> = {
    account: {
        bids: {
            title: 'Twoje oferty',
            emptyState: {
                base: 'Nie złożyłeś jeszcze oferty w żadnej aukcji.',
                cta: 'Bierz udział w aukcjach',
            },
            filters: {
                bidPosition: {
                    base: 'Pozycja oferty',
                    anyOption: 'Pozycje wszystkich ofert',
                    maxBidder: 'Twoja oferta jest najwyższa',
                    outbid: 'Ktoś Cię przelicytował',
                },
                state: {
                    base: 'Stan aukcji',
                    anyOption: 'Stan wszystkich aukcji',
                    [AuctionState.Closed]: 'Zamknięte aukcje',
                    [AuctionState.Running]: 'Prowadzenie aukcji',
                },
            },
        },
        sales: {
            title: 'Twoja sprzedaż',
            emptyStateText: 'Nie istnieją aukcje ze sprzedającym powiązanym z tym kontem.',
        },
    },
    common: {
        logout: 'Wyloguj się',
        login: 'Zaloguj się',
        loginSignup: 'Zaloguj się lub zarejestruj',
        loginTitle: 'Zaloguj się do Fleequid',
        profile: 'Twój profil',
        searchPlaceholder: 'Szukaj…',
        searchNoResults: 'No results found for "{query}"',
        email: 'Adres e-mail',
        yourAccount: 'Twoje konto',
        language: 'Język',
    },
    cookie: {
        name: 'Nazwa',
        provider: 'Dostawca',
        purpose: 'Cel',
        expiry: 'Okres ważności',
        type: 'Typ',
    },
    error: {
        backend: {
            [BackendErrorCode.InvalidEnumValue]: 'Nieprawidłowy typ danych',
            [BackendErrorCode.InvalidDate]: 'Nieprawidłowy format danych',
            [BackendErrorCode.InvalidLength]: 'Nieprawidłowa długość. Sprawdź, czy w tym ciągu nie brakuje znaków lub nie umieszczono w nim dodatkowych znaków',
            [BackendErrorCode.NotAString]: 'Nieprawidłowy typ danych; dozwolone są tylko litery',
            [BackendErrorCode.EmptyString]: 'Brakujące informacje. Uzupełnij wszystkie wymagane dane',
            [BackendErrorCode.PrismaError]: 'Nie można zapisać danych; spróbuj użyć innej wartości',
            [BackendErrorCode.AuthAuthenticationVersionMismatch]: 'Twoja sesja wygasła. Zaloguj się ponownie, aby kontynuować',
            [BackendErrorCode.AuthWrongCredentials]: 'Nieprawidłowe dane uwierzytelniające',
            [BackendErrorCode.AuthNoUserByEmail]: 'Z wprowadzonym adresem e-mail nie jest powiązane żadne konto.',
            [BackendErrorCode.AuthWrongPassword]: 'Wprowadzone hasło jest nieprawidłowe.',
            [BackendErrorCode.UserIsBanned]: 'Twoje konto zostało zawieszone. W celu uzyskania dalszych informacji skontaktuj się z nami.',
            [BackendErrorCode.AuthEmailNotVerified]: 'Wprowadzony adres e-mail nie został jeszcze zweryfikowany. Sprawdź skrzynkę odbiorczą lub skontaktuj się z nami.',
            [BackendErrorCode.SignupUserAlreadyExists]: 'Adres e-mail już zarejestrowany, jeśli zapomniałeś hasła, spróbuj je odzyskać.',
            [BackendErrorCode.UploadFileError]: 'Wystąpił błąd podczas przesyłania pliku',
            [BackendErrorCode.IsNotTrue]: 'Brakujący wybór: wybierz żądaną opcję, aby kontynuować',
            [BackendErrorCode.EntityNotFound]: 'Nie znaleziono wyników',
            [BackendErrorCode.PasswordResetEmailNotExists]: 'Z tym adresem e-mail nie jest powiązane żadne konto',
            [BackendErrorCode.EmailChangeWrongPassword]: 'Nie możesz zmienić adresu e-mail, ponieważ hasło jest nieprawidłowe',
            [BackendErrorCode.CantBidUserCompanyEqSellerCompany]: 'Ten zasób należy do Twojej firmy; nie możesz go kupić.',
            authentication: 'Błąd uwierzytelniania; spróbuj zalogować się ponownie',
        },
        '404': {
            title: '404',
            subtitle: 'Ups! Nie znaleziono strony',
            description: 'Przepraszamy, ale strona, której szukasz, nie istnieje. Na Fleequid znajdziesz jednak mnóstwo innych rzeczy do odkrycia.',
            button: 'Powrót do strony głównej',
        },
        '500': {
            title: '500',
            subtitle: 'Ups! Wystąpił błąd',
            description: 'Przepraszamy za utrudnienia. Już nad tym pracujemy.',
            help: {
                base: 'W międzyczasie możesz spróbować później lub {contactUs}',
                contactUs: 'skontaktuj się z nami pod adresem {email} lub, w nagłych wypadkach, pod numerem telefonu {phone}',
            },
            button: 'Powrót do strony głównej',
        },
        default: {
            title: 'Błąd',
            subtitle: 'Wystąpił nieoczekiwany błąd',
            description: 'Już pracujemy nad jego rozwiązaniem.',
            button: 'Powrót do strony głównej',
        },
    },
    a11y: {
        auctionDetail: 'Dowiedz się więcej o {name}',
    },
    adp: {
        imageGallery: 'Pełna galeria ({totalImages})',
        videoGallery: 'Obejrzyj filmy ({totalVideos})',
        imagePagination: 'Zdjęcie {0} z {1}',
        videoPagination: 'Wideo {0} z {1}',
        papers: 'Dokumenty',
        registrationDocument: 'Dowód rejestracyjny pojazdu',
        cocDocument: 'Świadectwo zgodności',
        cocDocumentAbsent: 'Świadectwo zgodności nie jest dostępny.',
        maintenanceCard: 'Historia serwisowa',
        detailsTitle: 'Dane pojazdu | Dane pojazdów',
    },
    asset: {
        type: {
            [AssetType.Bus]: 'Autobus | Autobusy',
        },
        class: {
            base: 'Klasa',
            [BusClass.ClassOne]: 'Miasto',
            [BusClass.ClassTwo]: 'Międzymiastowe',
            [BusClass.ClassThree]: 'Turystyczne',
            [BusClass.ClassA]: 'Klasa A',
            [BusClass.ClassB]: 'Klasa B',
            [BusClass.SchoolBus]: 'Autobus szkolny',
        },
        firstRegistration: 'Data pierwszej rejestracji',
        kilometers: 'Przebieg',
        antiPollution: 'Norma emisji spalin',
        euroNorm: {
            [AssetEuroNorm.Euro0]: 'Euro 0',
            [AssetEuroNorm.Euro1]: 'Euro 1',
            [AssetEuroNorm.Euro2]: 'Euro 2',
            [AssetEuroNorm.Euro3]: 'Euro 3',
            [AssetEuroNorm.Euro4]: 'Euro 4',
            [AssetEuroNorm.Euro5]: 'Euro 5',
            [AssetEuroNorm.Euro6]: 'Euro 6',
        },
        toBeScrapped: 'Do złomowania',
        expiredInspection: 'Przegląd wygasł',
        bundle: 'Pakiet',
        vehicleSpecs: 'Specyfikacje pojazdu',
        bundleSpecs: 'Specyfikacje pakietu',
        vehiclesInBundle: 'Pojazdy zawarte w pakiecie',
        vehicleIndex: 'Pojazd #{index}',
        vehicleCount: '{count} pojazdów',
        description: 'Opis',
        version: 'Wersja',
        geographicalLocation: 'Lokalizacja',
        vehicleCondition: {
            base: 'Stan pojazdu',
            [VehicleCondition.Blocked]: 'Zablokowany',
            [VehicleCondition.Rollable]: 'Możliwość holowania',
            [VehicleCondition.Running]: 'Gotowy do jazdy',
        },
        registrationDocumentCountry: 'Narodowość dokumentu',
        conditionsAndMaintenance: 'Stan i Konserwacja',
        stateOfUse: {
            present: 'Obecny',
            missing: 'Brakujący',
            [AssetStateOfUse.Insufficient]: 'Niewystarczający',
            [AssetStateOfUse.WorkingAndMaintained]: 'Działa i jest regularnie serwisowany',
            [AssetStateOfUse.LikeNew]: 'Jak nowy',
        },
        stateOfUseGearbox: 'Stan skrzyni biegów',
        stateOfUseAirConditioner: 'Stan klimatyzacji',
        stateOfUseBreakingSystem: 'Stan układu hamulcowego',
        stateOfUseDrivingOrgans: 'Stan organów kierujących',
        stateOfUseEngine: 'Stan silnika',
        stateOfUsePowerSystem: 'Stan układu zasilania',
        stateOfUseTimingBelt: 'Stan paska rozrządu',
        stateOfUseTransmissionDevices: 'Stan urządzeń przeniesienia napędu',
        stateOfUseTurbine: 'Stan turbiny',
        missingParts: 'Brakujące części',
        useType: {
            base: 'Typ użytkowania',
            [AssetUseType.OwnUse]: 'Własny',
            [AssetUseType.ThirdPartRent]: 'Wynajem dla osób trzecich',
            [AssetUseType.Line]: 'Linia',
        },
        enginePosition: 'Pozycja silnika',
        position: {
            [AssetPartPosition.Rear]: 'Tylny',
            [AssetPartPosition.Central]: 'Centralny',
            [AssetPartPosition.Frontal]: 'Przedni',
        },
        length: 'Długość',
        height: 'Wysokość',
        width: 'Szerokość',
        vin: 'Numer identyfikacyjny pojazdu',
        converter: 'Konwerter',
        visitDate: {
            base: 'Możliwość obejrzenia',
            fromTo: 'w dniu {date} w godz. od {fromTime} – do {toTime}',
        },
        inspectionExpiryDate: 'Data ważności przeglądu',
        doors: {
            base: 'Drzwi',
            [AssetDoors.Double]: '2',
            [AssetDoors.DoubleDouble]: '2+2',
            [AssetDoors.DoubleDoubleDouble]: '2+2+2',
            [AssetDoors.DoubleDoubleSingle]: '2+2+1',
            [AssetDoors.DoubleSingleSingle]: '2+1+1',
            [AssetDoors.Single]: '1',
            [AssetDoors.SingleDouble]: '1+2',
            [AssetDoors.SingleSingle]: '1+1',
            [AssetDoors.SingleDoubleSingle]: '1+2+1',
            [AssetDoors.SingleSingleSingle]: '1+1+1',
            [AssetDoors.SingleDoubleDouble]: '1+2+2',
        },
        seatUpholstery: {
            base: 'Tapicerka foteli',
            [AssetSeatUpholstery.Fabric]: 'Materiał tekstylny',
            [AssetSeatUpholstery.Leather]: 'Skóra',
            [AssetSeatUpholstery.Plastic]: 'Tworzywo sztuczne',
            [AssetSeatUpholstery.FabricLeather]: 'Materiał tekstylny i skóra',
        },
        seats: 'Fotele',
        totalCapacity: 'Całkowita pojemność pasażerska',
        seatsFromS1: 'Miejsca, w tym fotel kierowcy, z certyfikatu rejestracyjnego S1',
        standingPlaces: 'Miejsca stojące',
        standingPlacesFromS2: 'Miejsce stojące – pole S2 w dowodzie rejestracyjnym',
        foldingSeats: 'Składane siedzenia',
        wheelchairPlaces: 'Miejsca dla wózków inwalidzkich',
        companionSeats: 'Miejsca dla osób towarzyszących',
        wheelchairRamp: {
            base: 'Pochylnia dla wózków',
            [AssetWheelchairRamp.Absent]: 'Brak',
            [AssetWheelchairRamp.Electric]: 'Elektryczny',
            [AssetWheelchairRamp.Manual]: 'Ręczna',
            [AssetWheelchairRamp.Electropneumatic]: 'Elektropneumatyczny',
        },
        luggageCapacity: 'Pojemność bagażnika',
        passThroughLuggageCompartment: 'Przelotowy przedział bagażowy',
        hatBox: 'Pudełko na kapelusze',
        transmission: 'Skrzynia biegów',
        gearboxType: {
            base: 'Rodzaj skrzyni biegów',
            [AssetGearboxType.Auto]: 'Automatyczna',
            [AssetGearboxType.Manual]: 'Ręczna',
            [AssetGearboxType.SemiAuto]: 'Półautomatyczna',
        },
        gearboxBrand: 'Marka skrzyni biegów',
        gearboxModel: 'Model skrzyni biegów',
        glasses: {
            base: 'Okna',
            [Glasses.Single]: 'Pojedyncza szyba',
            [Glasses.Double]: 'Podwójna szyba',
        },
        openableWindows: 'Otwierane okna',
        engine: 'Silnik',
        engineBrand: 'Marka silnika',
        engineModel: 'Model silnika',
        hp: 'Moc silnika',
        kw: 'Moc',
        displacement: 'Pojemność',
        cylinders: 'Liczba cylindrów',
        fuel: {
            base: 'Paliwo',
            [AssetFuel.CNG]: 'CNG',
            [AssetFuel.Electric]: 'Elektryczny',
            [AssetFuel.GPL]: 'LPG',
            [AssetFuel.Diesel]: 'Olej napędowy',
            [AssetFuel.Gasoline]: 'Benzyna',
            [AssetFuel.Hybrid]: 'Hybrydowy',
            [AssetFuel.Hydrogen]: 'Wodór',
        },
        fuelTankCapacity: 'Pojemność zbiornika paliwa',
        gasTanks: 'Zbiorniki gazu',
        gasTanksMaterial: {
            base: 'Materiał zbiornika paliwa',
            [GasTanksMaterials.Aluminum]: 'Aluminium',
            [GasTanksMaterials.Composite]: 'Kompozyt',
            [GasTanksMaterials.Steel]: 'Stal',
        },
        batteryPower: 'Pojemność akumulatora',
        chargingType: 'Rodzaj ładowania',
        axlesTires: 'Osie i opony',
        wheelSize: 'Rozmiar kół',
        wheels: 'liczba kół (pojedyncze lub podwójne)',
        axles: 'Osie',
        wheelsSizeAxle1: 'Rozmiar opon dla osi 1',
        wheelsSizeAxle2: 'Rozmiar opon dla osi 2',
        wheelsSizeAxle3: 'Rozmiar opon dla osi 3',
        alloyWheels: 'Felgi aluminiowe',
        spareWheel: 'Koło zapasowe',
        firePreventionSystem: 'Sygnalizacja pożarowa',
        firePreventionSystemBrand: 'Marka sygnalizacji pożarowej',
        abs: 'ABS',
        asr: 'ASR',
        retarder: 'Retarder',
        breathalyzer: 'Alkomat',
        tachograph: { base: 'Tachograf', [AssetTachograph.Absent]: 'Nieobecny', [AssetTachograph.Analog]: 'Analogowy', [AssetTachograph.Digital]: 'Cyfrowy' },
        indicatorSigns: 'Tablice z celem podróży',
        indicatorSignsBrand: 'Marka tablic z celem podróży',
        accessories: 'Akcesoria',
        cruiseControl: {
            base: 'Tempomat',
            [CruiseControlType.No]: 'Brak',
            [CruiseControlType.Adaptive]: 'Adaptacyjny',
            [CruiseControlType.Standard]: 'Standardowy',
        },
        deckType: {
            base: 'Podłoga',
            [DeckType.LowEntry]: 'Obniżone wejście',
            [DeckType.LowFloor]: 'Niska podłoga',
            [DeckType.NormalFloor]: 'Normalna podłoga',
        },
        acDriver: 'Klimatyzacja kabiny kierowcy',
        driverBerth: 'Miejsce do spania dla kierowcy',
        wifi: 'Wi-Fi',
        cctv: 'Monitoring CCTV',
        internalCameras: 'Kamery wewnętrzne',
        externalCameras: 'Kamery zewnętrzne',
        retrocamera: 'Kamera cofania',
        microphone: 'Mikrofon',
        cdPlayer: 'Odtwarzacz CD',
        dvdPlayer: 'Odtwarzacz DVD',
        usbPlayer: 'Radioodtwarzacz USB',
        heater: 'Nagrzewnica',
        preHeater: 'Nagrzewnica postojowa',
        kitchen: 'Kuchnia',
        coffeeMachine: 'Ekspres do kawy',
        refrigerator: 'Lodówka',
        toilet: 'Toaleta',
        safetyBelts: 'Pasy bezpieczeństwa',
        acPassengers: 'Klimatyzacja kabiny pasażerów',
        curtains: 'Zasłony',
        seatPocket: 'Kieszeń fotela',
        footRest: 'Podnóżek',
        handRest: 'Podłokietnik',
        mp3: 'MP3',
        monitors: 'Monitor',
        seatsMonitor: 'Monitory w oparciach foteli',
        seatsUsbPorts: 'Porty USB w fotelach',
        socket220: 'Gniazdo elektryczne kierowcy',
        seatsSocket220: 'Gniazda elektryczne w fotelach pasażerów 220 V',
        seatsRadio: 'Radio w fotelach',
        extendableSeats: 'Rozkładane fotele',
        recliningSeats: 'Odchylane fotele',
        coffeeTables: 'Stoliki w oparciach foteli',
    },
    auction: {
        countdown: {
            startsIn: 'Rozpoczęcie za',
            endsIn: 'Zakończenie za',
            days: 'd',
            hours: 'h',
            minutes: 'm',
            seconds: 's',
        },
        bail: {
            title: 'Wnieś wadium, aby wziąć udział w aukcji',
            description: 'Aby wziąć udział w tej aukcji, musisz wnieść wadium za pomocą uznawanej formy płatności.',
            amount: 'WADIUM',
            note: 'Wadium zostanie zwrócone po zakończeniu aukcji, jeśli nie znajdziesz się wśród osób, które zaoferowały najwyższą cenę.',
            subnote: 'Wysokość wadium nie jest taka sama dla wszystkich aktywów i może się różnić w zależności od ceny wywoławczej',
            error: 'Podczas przetwarzania transakcji wystąpił błąd; spróbuj ponownie później lub skontaktuj się z zespołem pomocy',
            submit: 'Wnieś wadium',
        },
        bid: {
            title: 'Zostań najwyższym licytantem teraz',
            description: 'Pamiętaj, że oferta pojedyncza lub maksymalna jest wiążąca.',
            autoBidEditTitle: 'Zaktualizuj automatyczną ofertę',
            autoBidEditDescription: 'Pamiętaj, że maksymalna oferta jest wiążąca.',
            activeAutoBid: 'Automatyczna oferta aktywna do',
            actualBid: 'Aktualna oferta',
            autoBidToggle: 'Automatyczne licytowanie ({0})',
            autoBidLink: 'co to jest?',
            yourBid: 'Twoja oferta',
            yourMaxBid: 'Twoja maksymalna oferta',
            restoreMinBid: 'Zresetuj',
            confirmSingleBid: 'Złóż ofertę',
            confirmAutoBid: 'Aktywuj automatyczną ofertę',
            confirmAutoBidUpdate: 'Zaktualizuj automatyczną ofertę',
            cancel: 'Anuluj',
            success: 'Oferta została pomyślnie złożona!',
            outbid: 'Inny użytkownik przelicytował twoją ofertę',
            expiredError: {
                title: 'Aukcja jest zakończona',
                description: 'Nie można złożyć dalszych ofert.',
            },
        },
        bidButton: 'Złóż ofertę',
        bidButtonActiveAutoBid: 'Zmień automatyczną ofertę',
        auctionPanel: {
            reservePrice: 'Cena minimalna',
            startingPrice: 'Cena wywoławcza',
            current: 'Bieżące',
            wonAuction: 'Aukcja wygrana!',
            state: {
                [AuctionState.Closed]: 'Aukcja zamknięta',
                [AuctionState.Canceled]: 'Aukcja anulowana',
                [AuctionState.Draft]: 'Wersja robocza aukcji',
            },
            maxBidder: 'Twoja oferta jest najwyższa!',
            outBid: 'Ktoś Cię przelicytował!',
            negotiationInProgress: 'Negocjacje w toku',
            feeNotice: '+ opłaty ({0})',
            feeNoticeLink: 'co to są?',
            priceNotAvailable: 'N/A',
            bidNotice: {
                reservePriceNotReached: {
                    base: 'Cena minimalna {0}',
                    notReached: 'jeszcze nie została osiągnięta',
                },
                notScrapyard: 'Aukcja zastrzeżona dla osób prowadzących złomowiska wpisanych do rejestru podmiotów gospodarujących odpadami kat. 5 z kodem EKO 16.01.04',
                sameCompany: {
                    [AuctionState.Running]: 'Nie możesz wziąć udziału, ponieważ ten pojazd należy już do Twojej firmy',
                    base: 'Ten pojazd należy już do Twojej firmy',
                },
                isAdmin: 'Nie możesz wziąć udziału, ponieważ Twój użytkownik ma rolę administratora',
                unapprovedCompany: 'Nie możesz składać ofert, ponieważ przeglądamy dokumenty Twojej firmy.',
            },
        },
        needSupport: 'Potrzebujesz pomocy?',
        contactUs: 'Skontaktuj się z obsługą klienta Fleequid',
    },
    auctions: {
        page: {
            asset: {
                [AssetType.Bus]: 'autobus | autobusy',
            },
            title: 'Odkryj wszystkie {title} na Fleequid',
            results: 'Widoczne aukcje: {total}',
            emptyStateTitle: 'Brak wyników',
            emptyStateText: 'Zastosowane filtry nie dały żadnych wyników.',
            resetFilters: 'Usuń filtry',
        },
        filters: {
            applied: 'Zastosowane filtry',
            button: {
                label: 'Filtruj aukcje',
            },
            modal: {
                title: 'Filtruj aukcje',
                back: 'Powrót do rezultatów',
            },
            label: 'Filtry',
            class: 'Klasa',
            brand: 'Marka, model, wersja',
            brandFilter: 'Marka',
            brandFilterAnyOption: '',
            modelFilter: 'Model',
            modelFilterAnyOption: '',
            versionFilter: 'Wersja',
            versionFilterAnyOption: '',
            antiPollution: 'Norma emisji spalin',
            registration: 'Rejestracja',
            yearFrom: 'Od (rok)',
            yearTo: 'Do (rok)',
            yearAnyOption: 'Wszystkie lata',
            kilometers: 'Przebieg',
            kmFrom: 'Od',
            kmTo: 'Do',
            kmAnyOption: 'Dowolny przebieg',
            state: {
                title: 'Stan aukcji',
                all: 'Wszystkie stany',
                [AuctionState.Pending]: 'Aukcje, które się jeszcze nie rozpoczęły',
                [AuctionState.Running]: 'Trwające aukcje',
                [AuctionState.Closed]: 'Zamknięte aukcje',
            },
            scrapping: {
                title: 'Ograniczenia dotyczące złomowania',
                help: 'Czym są ograniczenia dotyczące złomowania?',
                all: 'Wszystkie pojazdy',
                [RequireScrappingFilter.requireScrapping]: 'Z ograniczeniami dotyczącymi złomowania',
                [RequireScrappingFilter.doNotRequireScrapping]: 'Bez ograniczeń dotyczących złomowania',
            },
            fuel: 'Paliwo',
            length: 'Długość',
            lengthFrom: 'Od',
            lengthTo: 'Do',
            lengthAnyOption: 'Dowolna długość',
            empty: 'Nie zastosowano filtrów.',
        },
        sorting: {
            title: 'Sortuj według',
            yearAsc: 'Najstarsza rejestracja',
            yearDesc: 'Najnowsza rejestracja',
            priceAsc: 'Najtańsze',
            priceDesc: 'Najdroższe',
            kmAsc: 'Rosnący przebieg',
            kmDesc: 'Malejący przebieg',
            defaultByState: {
                all: 'Domyślna kolejność',
                [AuctionState.Running]: 'Najszybszy koniec',
                [AuctionState.Closed]: 'Najbardziej niedawny koniec',
                [AuctionState.Pending]: 'Najszybszy start',
            },
        },
        appliedFilters: {
            from: 'od: {0}',
            to: 'do: {0}',
            reset: 'Zresetuj filtry',
            appliedFiltersCounter: '{count} aktywny filtr | aktywne filtry: {count}',
        },
    },
    homepage: {
        link: 'Strona główna',
        hero: {
            title: 'Nie ma pojazdów o dużej masie',
            subtitle: 'Weź udział w naszych aukcjach internetowych i kupuj lub sprzedawaj autobusy w prosty, szybki i przejrzysty sposób',
            buttonLabel: 'Bierz udział w aukcjach',
        },
        whatIsFleequid: {
            title: 'Co to jest Fleequid?',
            description: 'Fleequid to nowy sposób sprzedawania i kupowania pojazdów przez firmy na aukcjach',
            section: {
                simplicity: {
                    title: 'Prostota',
                    description: 'Prosty i intuicyjny proces, który eliminuje konieczność prowadzenia długotrwałych negocjacji.',
                },
                speed: {
                    title: 'Szybkość',
                    description: 'Nowy autobus można kupić w ciągu 7 dni',
                },
                transparency: {
                    title: 'Przejrzystość',
                    description: 'Przejrzyste ceny, szczegółowe zdjęcia, indywidualne wsparcie',
                },
            },
        },
        vision: {
            caption: 'Nasza wizja',
            title: 'Wierzymy w zalety pojazdów używanych. Chcemy pomagać firmom w zarządzaniu kupnem i sprzedażą pojazdów firmowych, pozwalając oszczędzać czas i zasoby, dzięki czemu problem staje się szansą.',
            highlight: 'Możesz zapomnieć o ryzyku i niepewności: dzięki nam nadanie wartości Twoim aktywom staje się proste.',
        },
        howItWorks: {
            title: 'Jak to działa?',
            description: 'Dokładamy starań, aby zapewnić prostą i intuicyjną obsługę naszej platformy, co pozwoli Ci z łatwością zarządzać flotą autobusów',
            signUp: {
                title: 'Zarejestruj się w Fleequid',
                create: 'Utwórz {account}, korzystając z danych Twojej firmy',
                account: 'nowe konto',
                verify: 'Potwierdź swoje dane i {browse} stronę',
                browse: 'zacznij przeglądać',
            },
            join: {
                title: 'Bierz udział w aukcjach',
                choose: 'Wybierz {bus} z listy',
                bus: 'autobus',
                add: 'Dodaj poprawną {payment}, aby wziąć udział aukcjach',
                payment: 'sposób płatności',
                follow: 'Obserwuj aukcje i {win}',
                win: 'wygrywaj autobusy, które Ci odpowiadają',
            },
            sell: {
                title: 'Sprzedawaj autobusy firmowe',
                letUsKnow: 'Podaj nam wszystkie szczegóły dotyczące {bus}',
                bus: 'sprzedawanego autobusu',
                choose: 'Wybierz {price}, którą chcesz otrzymać za autobus',
                price: 'cena minimalna', // TODO
                wait: 'Zaczekaj, aż aukcja się zakończy, aby {confirm}',
                confirm: 'potwierdź sprzedaż',
            },
        },
        whyFleequid: {
            title: 'Dlaczego warto wybrać Fleequid?',
            description: 'Chcemy być Twoim zaufanym partnerem w zarządzaniu flotą',
            section: {
                euReach: {
                    title: 'Europejski zasięg',
                    description: 'Oszczędność czasu dzięki wyeliminowaniu osób niezdecydowanych i uproszczeniu procedur związanych z administracją i dokumentacją.',
                },
                unifiedBilling: {
                    title: 'Ujednolicone rozliczenia',
                    description: 'Wygoda korzystania z wydajnej platformy i jednej firmy, dla której wystawiasz faktury.',
                },
                easyAuctions: {
                    title: 'Łatwe i wygodne aukcje',
                    description: "Thanks to our pre- and post-purchase support service, participating in auctions will be child's play.",
                },
                operationalEfficiency: {
                    title: 'Efektywność operacyjna',
                    description: 'Oszczędność czasu dzięki wyeliminowaniu osób niezdecydowanych i uproszczeniu formalności.',
                },
                sell: {
                    title: 'Sprzedawaj pojazdy',
                    description: 'Fleequid pomaga ustalić cenę sprzedaży i stworzyć dokumentację fotograficzną.',
                    cta: 'Sprzedawaj na Fleequid',
                },
            },
        },
        startToday: {
            title: 'Zacznij już dziś na Fleequid',
            description: 'Zobacz wszystkie dostępne aukcje i przygotuj się na najlepsze produkty',
            cta: 'Bierz udział w aukcjach',
        },
    },
    faq: {
        title: 'Często zadawane pytania',
        start: {
            base: 'Jak to działa',
            participate: {
                q: 'Jak wziąć udział w aukcji internetowej?',
                a: 'Aby wziąć udział w aukcjach internetowych na Fleequid, musisz zarejestrować się na stronie {0}, podać dane swojej firmy i przesłać wymagane dokumenty. Gdy to zrobisz, otrzymasz możliwość przeglądania stron z trwającymi aukcjami i składania ofert, aby walczyć o zakup pojazdu. Aukcje odbywają się drogą elektroniczną. Oferty możesz składać do momentu zamknięcia aukcji. Aukcja kończy się, gdy użytkownik, który złożył najwyższą ofertę, wygra pojazd, pod warunkiem że jego oferta jest co najmniej równa cenie minimalnej, lub jeśli sprzedający zaakceptuje ofertę niższą od ceny minimalnej. ',
                link: 'https://fleequid.com/en/signup',
            },
            reservePrice: {
                q: 'Co to jest cena minimalna?',
                a: 'Cena minimalna to kwota, która musi zostać osiągnięta, aby aukcja mogła być uznana za rozstrzygniętą. Jeśli podczas aukcji nie uda się osiągnąć tej kwoty, sprzedający może zdecydować, czy zaakceptuje najlepszą otrzymaną ofertę. Cena minimalna jest wyższa niż cena wywoławcza: jest ona ustalana przez sprzedającego przed rozpoczęciem aukcji i nie jest widoczna dla kupujących, którzy widzą jedynie najwyższą dotychczasową ofertę.',
            },
            countries: {
                q: 'W których krajach można kupować i sprzedawać autobusy za pośrednictwem Fleequid?',
                a: 'Fleequid działa we Włoszech, w Unii Europejskiej i w kilku krajach spoza UE, stosując się do przepisów obowiązujących w poszczególnych krajach. Jeśli masz jakiekolwiek wątpliwości co do możliwości korzystania z Fleequid w swoim kraju, skontaktuj się z zespołem obsługi klienta: {0}.',
            },
            bureaucracy: {
                q: 'Kto zajmuje się przeniesieniem własności, rejestracją pojazdów i wszystkimi aspektami związanymi z dokumentacją?',
                a: "Fleequid will communicate to seller and buyer how to handle documentation aspects and payments related to the property transfer, depending on the specifics of the deal (between Italian companies, EU, and non-EU) and on the platform's role in the sales process. However, registration of the vehicle in the country of destination is the responsibility of the buyer.",
            },
            toBeScrapped: {
                q: 'Czym są pojazdy przeznaczone do złomowania?',
                a: 'Pojazdy przeznaczone do złomowania to te, które nie są już zdatne do ruchu i transportu osób. Zgodnie z obowiązującymi przepisami prawa lub regulacjami podlegają obowiązkowemu recyklingowi i częściowemu ponownemu wykorzystaniu ich komponentów jako części zamiennych. W aukcjach tych pojazdów mogą uczestniczyć wyłącznie podmioty zajmujące się złomowaniem, wpisane do rejestru gospodarowania odpadami w kategorii 5 i podmioty z kodem EKO 16.01.04. Obecnie aukcje pojazdów przeznaczonych do złomowania są dostępne tylko we Włoszech.',
            },
            vies: {
                q: 'Co to jest VIES? Dlaczego jest obowiązkowy?',
                a: 'To wyszukiwarka (a nie baza danych) stworzona przez Komisję Europejską w celu ułatwienia sprzedaży między firmami w UE. Jest to elektroniczny środek walidacji numerów identyfikacyjnych VAT podmiotów gospodarczych zarejestrowanych w Unii Europejskiej na potrzeby transgranicznych transakcji dotyczących towarów lub usług. Z tego względu firmy z Unii Europejskiej, które chcą korzystać z platformy Fleequid, muszą być możliwe do znalezienia w tej wyszukiwarce.',
            },
            coc: {
                q: 'Co to jest COC?',
                a: 'COC, czyli Certyfikat Zgodności, to dokument potwierdzający zgodność pojazdu z określonymi normami technicznymi i bezpieczeństwa ustanowionymi przez przepisy europejskie. Dokument ten zawiera różne informacje dotyczące pojazdu, w tym jego specyfikacje techniczne, numer podwozia, dane emisji, klasę homologacji oraz normy bezpieczeństwa, które spełnia pojazd. Jest on wymagany do rejestracji pojazdu w innym kraju w ramach Unii Europejskiej, ponieważ posiadanie tego dokumentu eliminuje potrzebę przeprowadzania dodatkowych kontroli technicznych. Z kolei brak COC podczas sprzedaży może nie tylko uniemożliwić rejestrację, ale także narazić sprzedawcę na sankcje.',
            },
        },
        sell: {
            base: 'Jak sprzedawać',
            selling: {
                q: 'Jak rozpocząć sprzedaż na Fleequid?',
                a: 'Jeśli masz autobus do sprzedania, skontaktuj się z zespołem obsługi klienta, pisząc na adres {0} lub wypełnij formularz na stronie {1}, podając informacje o pojeździe, który chcesz sprzedać.  Musisz również zarejestrować swoją firmę jako sprzedającego na platformie.',
            },

            startingPrice: {
                q: 'Jak definiowana jest cena wywoławcza?',
                a: 'Cena wywoławcza to minimalna cena pojazdu, powyżej której potencjalni nabywcy mogą składać oferty lub kontroferty na Marketplace. Oferta wywoławcza jest obliczana przez Fleequid na podstawie oceny technicznej pojazdu. Musi być ona niższa od ceny minimalnej, którą ustala sprzedający.',
            },
            delivery: {
                q: 'W jaki sposób pojazd jest dostarczany?',
                a: 'Po otrzymaniu płatności od kupującego sprzedający musi umożliwić mu odbiór pojazdu. Kupujący musi niezwłocznie przystąpić do odbioru, który musi odbyć się w ciągu dziesięciu dni od daty, w której Fleequid poinformuje kupującego o zakończeniu procedur wyrejestrowania w kraju pochodzenia autobusu, aby kupujący mógł zorganizować odbiór. Fleequid nakłada kary za każdy dzień opóźnienia wykraczający poza określony harmonogram.',
            },
            howLong: {
                q: 'Ile czasu zajmuje sprzedaż autobusu?',
                a: 'Po umieszczeniu informacji o pojeździe na platformie, Fleequid informuje sprzedającego o dacie rozpoczęcia i zakończenia aukcji oraz o okresie wyświetlania informacji przed rozpoczęciem aukcji; daty te są ustalane przez Fleequid w oparciu o specyfikę sprzedawanego pojazdu. Jeśli nie dojdzie do sprzedaży, Fleequid nadal będzie mieć prawo do przeprowadzenia do 3 kolejnych aukcji w ciągu 30 dni.',
            },
            expiredInspection: {
                q: 'Czy można kupić pojazd z wygasłym przeglądem technicznym?',
                a: 'Jeśli Twoja firma działa poza granicami Włoch, nie możesz kupić pojazdów znajdujących się we Włoszech, oznaczonych jako "wygasły przegląd techniczny". Pojazdy zarejestrowane we Włoszech z wygasłym przeglądem mogą być sprzedawane tylko na terenie kraju. Aby wyeksportować taki pojazd do innych krajów, musi on najpierw zostać wyrejestrowany z Włoskiego Publicznego Rejestru Pojazdów (PRA), a warunkiem tej wyrejestrowania jest ważny przegląd techniczny.',
            },
        },
        buy: {
            base: 'Jak kupować',
            inspection: {
                q: 'Czy możliwe jest sprawdzenie pojazdów przed licytacją?',
                a: 'Sprzedawca może zdecydować, czy zaoferować możliwość osobistej inspekcji pojazdu. Jeśli tak, sprzedawca wyznaczy konkretny dzień na wizyty i inspekcje pojazdu bezpośrednio w swojej lokalizacji.',
            },
            claims: {
                q: 'Jak rozwiązujecie kwestię wad ukrytych i reklamacji?',
                a: 'Fleequid działa jako pośrednik między sprzedającym a kupującym i jako taki nie ponosi odpowiedzialności za jakiekolwiek wady, zarówno ukryte, jak i widoczne, usterki, zużycie, nieprawidłowości, niezdatność do użytku, a także błędy lub nieścisłości w oświadczeniach złożonych przez sprzedającego. Publikując swoją aukcję na Fleequid, sprzedający zobowiązuje się do przejrzystości i podawania dokładnych informacji o stanie pojazdu; jednocześnie kupujący musi zobowiązać się do zgłaszania wszelkich rozbieżności w odpowiednim czasie (3 dni w przypadku widocznych wad) w ciągu miesiąca od odbioru w przypadku ukrytych wad pojazdu. Fleequid pomaga kupującym w rozwiązywaniu reklamacji i zapewnia szczegółowe warunki ich rozpatrywania.',
            },
            paymentMethods: {
                q: 'Jakie formy płatności są akceptowane?',
                a: 'Fleequid akceptuje przelewy bankowe i płatności kartą kredytową. Konkretnie, wadium należy wpłacić wyłącznie kartą kredytową; cenę zakupu pojazdu, prowizję i wszelkie dodatkowe koszty należy opłacić przelewem bankowym. ',
            },
            howPaymentWorks: {
                q: 'Jak działa płatność?',
                a: 'Użytkownik, który wygrywa aukcję, zobowiązany jest do zapłaty kwoty obejmującej cenę wygrywającą, opłatę sprzedażową dla Fleequid oraz wszelkie inne uzgodnione opłaty (patrz Warunki Ogólne dla szczegółów). Kupujący musi zapłacić należną kwotę przelewem bankowym nie później niż trzy dni po otrzymaniu odpowiedniej faktury. Płatności częściowe nie są dozwolone.',
            },
            deposit: {
                q: 'Jak działa wadium?',
                a: 'Wadium to suma pieniężna, którą użytkownik przekazuje Fleequid podczas składania oferty na aukcji, jako gwarancję wiarygodności oferty. Po zakończeniu aukcji wszystkie wadia są zwracane, z wyjątkiem wadiów kupujących, którzy złożyli najwyższą i drugą w kolejności ofertę. Jeśli zwycięzca aukcji zakupi pojazd, dokonując przelewu bankowego na uzgodnioną kwotę (nie później niż 3 dni po otrzymaniu odpowiedniej faktury), zarówno jego wadium, jak i wadium drugiego oferenta zostaną zwrócone. Jeśli zwycięzca nie dokona płatności w uzgodnionym terminie, straci zarówno prawo do zakupu autobusu, jak i wadium. W takim przypadku drugi w kolejności oferent zostanie automatycznie uznany za nowego zwycięzcę aukcji, a po zakupie pojazdu poprzez dokonanie przelewu bankowego wadium zostanie zwrócone.',
            },
            autoBid: {
                q: 'Jak działa Automatyczne Licytowanie?',
                a1: 'Automatyczne Licytowanie pozwala ustawić maksymalną kwotę, którą jesteś gotów zaoferować za aukcję (bez opłat).',
                a2: 'Po jego aktywacji system natychmiast składa minimalną ważną ofertę, biorąc pod uwagę cenę rezerwową i bieżące oferty innych użytkowników. Następnie, jeśli inni użytkownicy składają oferty, system automatycznie przebije je w twoim imieniu, zwiększając ofertę do ustalonej przez ciebie maksymalnej kwoty, po osiągnięciu której Automatyczne Licytowanie zostanie dezaktywowane.',
                a3: 'Automatyczne Licytowanie stara się również osiągnąć cenę rezerwową, aby maksymalnie zwiększyć twoje szanse na wygranie aukcji.',
                a4: 'Jeśli kilku użytkowników aktywuje Automatyczne Licytowanie z tą samą maksymalną kwotą, zostanie złożona oferta maksymalnej kwoty dla każdego użytkownika, ale za wygrywającą zostanie uznana tylko oferta użytkownika, który aktywował je jako pierwszy. Pamiętaj, że wszystkie złożone oferty są wiążące, nawet jeśli nie są wygrywające.',
                a5: 'Pamiętaj, że nie możesz anulować aktywnego Automatycznego Licytowania, ale możesz jedynie zwiększyć jego maksymalną kwotę.',
            },
        },
        contactUs: 'Mamy nadzieję, że udało Ci się znaleźć wszystkie odpowiedzi na swoje pytania na tej stronie – jeśli nie, chętnie pomożemy. Zadzwoń do nas pod numer {0} lub skontaktuj się z nami, pisząc na adres {1}.',
    },
    footer: {
        cookiePolicy: {
            base: 'Polityka plików cookie',
            changeConsent: 'Zmień swoją zgodę',
            revokeConsent: 'Cofnij swoją zgodę',
        },
        explore: 'Odkrywaj',
        legal: 'Strony z informacjami prawnymi',
        support: 'Potrzebujesz pomocy?',
        vat: 'Numer VAT',
        backTop: 'Powrót do góry',
    },
    menu: {
        buy: 'Kupuj',
        sell: 'Sprzedawaj',
        faq: 'Pytania i odpowiedzi',
    },
    mktg: {
        whyFleequid: {
            title: 'Dlaczego Fleequid?',
            deal: {
                title: 'Łatwe transakcje',
                description: 'Platforma technologiczna zastępująca negocjacje z niezdecydowanymi rozmówcami',
            },
            processes: {
                title: 'Szybkie procesy',
                description: 'Fleequid oszczędza czas i energię Twojego zespołu',
            },
            support: {
                title: 'Transparentne wsparcie',
                description: 'Wszystko jest jasne – od rejestracji po dostawę pojazdu',
            },
        },
    },
    notifications: {
        goToAuction: 'Przejdź do aukcji',
        success: 'Powodzenie',
        warning: 'Warnung',
        error: 'Błąd',
        genericError: 'Coś poszło nie tak. Spróbuj ponownie później.',
        tryAgain: 'Spróbuj ponownie',
        userOutBid: {
            title: 'Twoja oferta została przelicytowana!',
            description: 'Twoja oferta {prevBidAmount} dla {subject} została przebita; aktualna cena wynosi {currentBidAmount}',
            inAuction: 'ta aukcja',
            linkedAuction: '{brand} {model} {version}',
        },
        userBailAuthorized: {
            title: 'Jesteś upoważniony do wzięcia udziału w aukcji!',
            description: 'Zostałeś upoważniony do udziału w {context}.',
            inAuction: 'Możesz wziąć udział w tej aukcji',
            linkedAuction: 'Możesz wziąć udział w aukcji {brand} {model}',
        },
        userMaxBidderRestored: {
            title: 'Jesteś najwyższym oferentem!',
            description: 'Twoja oferta {prevBidAmount} dla {subject} jest teraz najwyższa!',
            inAuction: 'ta aukcja',
            linkedAuction: '{brand} {model} {version}',
        },
        auctionWon: {
            title: 'Aukcja zakończona - Wygrałeś!',
            description: 'Z przyjemnością informujemy, że wygrałeś {subject} z ofertą {currentPrice}. {reserve}',
            inAuction: 'tę aukcję',
            linkedAuction: 'aukcję na {brand} {model} {version}',
            reservePriceNotReached: 'Niestety, cena minimalna nie została osiągnięta. Skontaktujemy się ze sprzedawcą, aby zaproponować Twoją ofertę i sprawdzić, czy jest on skłonny kontynuować sprzedaż. Będziemy Cię informować o wszelkich postępach.',
        },
    },
    privacy: {
        link: 'Polityka prywatności',
    },
    seo: {
        auction: {
            title: '{asset} {brand} {model} {version} {vin}',
            description: 'Kup {brand} {model} {version}, {asset} używany na Fleequid.com: dołącz do aukcji B2B i złóż ofertę na {asset} {vin} marki {brand}, model {model}, w wersji {version}.',
            ogTitle: '{asset} {brand} {model} {version}',
            ogDescription: '{year}, {euroNorm}, {kilometers}: Weź udział w aukcji B2B i złóż ofertę na ten używany {asset} bezpośrednio online na Fleequid.com.',
        },
        auctions: {
            title: 'Używany pojazd {0} na sprzedaż',
            description: 'Kupuj online używane pojazdy {0} na Fleequid.com: łatwo bierz udział w aukcjach B2B najlepszych używanych pojazdów {1} na aukcji.',
        },
        cookiePolicy: {
            title: 'Polityka plików cookie',
            description: 'Dowiedz się więcej o zarządzaniu plikami cookie na portalu Fleequid: dowiedz się, w jaki sposób wykorzystujemy pliki cookie, aby poprawić wygodę przeglądania.',
        },
        homepage: {
            title: 'Autobusy na sprzedaż',
            description: 'Kupuj lub sprzedawaj autobusy i pojazdy użytkowe na aukcjach: dołącz do Fleequid i z łatwością uczestnicz w profesjonalnych aukcjach B2B pojazdów przemysłowych.',
        },
        privacyPolicy: {
            title: 'Polityka prywatności',
            description: 'Polityka prywatności portalu Fleequid: w jasny sposób wyjaśniamy, jak przetwarzamy informacje o korzystaniu ze strony oraz Twoje dane.',
        },
        signup: {
            title: 'Aukcje pojazdów online: rejestracja',
            description: 'Zarejestruj się teraz na Fleequid, aby łatwo i wygodnie uczestniczyć w aukcjach nowych i używanych pojazdów przemysłowych, autobusów i autokarów.',
            confirm: {
                title: 'Weryfikacja przez email',
            },
        },
        termsAndConditions: {
            title: 'Regulamin',
            description: 'Regulamin sprzedaży i kupna na Fleequid, internetowym portalu aukcji i skupu pojazdów przemysłowych i autobusów.',
        },
        pricingSeller: {
            title: 'Wycena dla sprzedającego',
            description: 'Opłaty za aukcje na Fleequid.com: szczegóły dotyczące cen i metody naliczania opłat za aukcje.',
        },
        pricingBuyer: {
            title: 'Wycena dla kupującego',
            description: 'Opłaty za aukcje na Fleequid.com: szczegóły dotyczące cen i metody naliczania opłat za aukcje.',
        },
    },
    signup: {
        link: 'Zarejestruj się',
        subtitle: 'Podaj dane swojej firmy i dodaj nowe zasoby do floty.',
        companyRegistration: 'Zarejestruj swoją firmę',
        companyInfo: 'Informacje o firmie',
        companyDocuments: 'Dokumentacja firmowa',
        contactPerson: 'Osoba kontaktowa w firmie',
        accessCredentials: 'Poświadczenia dostępu',
        fleetHandling: 'Obsługa floty',
        fields: {
            address: 'Adres',
            postCode: 'Kod pocztowy',
            city: 'Miasto',
            province: 'Województwo/region',
            businessName: 'Firma (nazwa)',
            country: 'Kraj',
            pec: 'Poświadczony adres e-mail',
            sdi: 'Identyfikator SDI',
            vatId: {
                base: 'Nr VAT',
                warning: 'Nr VAT wydaje się być nieprawidłowy.',
                validationUnavailable: 'Nie udało nam się zweryfikować Nr VAT dla wprowadzonego kraju. Przedstawiciel obsługi klienta sprawdzi Twoje dane po zakończeniu procesu rejestracji.',
            },
            companyRegistrationReportFile: 'Raport rejestracyjny firmy',
            firstname: 'Imię',
            lastname: 'Nazwisko',
            phone: 'Telefon',
            type: {
                label: 'Rodzaj podmiotu',
                [UserType.Dealer]: 'Salon sprzedaży',
                [UserType.PeopleTransportOperator]: 'Przewoźnik transportu osób',
                [UserType.Scrapyard]: 'Operator złomowiska',
                [UserType.Other]: 'Inny',
            },
            email: 'Adres e-mail',
            password: 'Hasło',
            confirmPassword: 'Potwierdź hasło',
            stats: {
                sales: 'Ile autobusów spodziewasz się sprzedać w roku {year}?',
                purchase: 'Ile używanych autobusów spodziewasz się kupić w roku {year}?',
                unknown: "I don't know",
            },
            consent: 'Zgoda',
            termsConditions: 'Potwierdzam przeczytanie i zgodę na {0}.',
            vexatiousClauses: 'Potwierdzam przeczytanie i zgodę na następujące klauzule:',
            privacy: 'Potwierdzam przeczytanie i zgodę na {0}.',
        },
        hint: {
            phone: {
                text: 'Format liczbowy',
                rule: 'Podaj numer telefonu w formacie +39 1234567890',
            },
        },
        validations: {
            requiredSDIOrPec: 'Wprowadź co najmniej jeden kod SDI lub adres PEC',
            companyRegistration: 'Wybrany plik jest zbyt duży. Maksymalny dozwolony rozmiar pliku to {size} MB',
        },
        success: {
            title: 'Dziękujemy za rejestrację!',
            p1: 'Potwierdzamy odbiór Twoich danych.',
            p2: 'Wkrótce otrzymasz wiadomość e-mail z potwierdzeniem i linkiem do weryfikacji adresu e-mail.',
            p3: 'Zachęcamy również do sprawdzenia folderu ze spamem w skrzynce odbiorczej. Wiadomość mogła zostać oznaczona jako spam ze względu na ustawienia poczty.',
            p4: 'Zapraszamy do przejrzenia aukcji internetowych, aby przekonać się, czy są wśród nich pojazdy, które Cię interesują. Chcesz sprzedać swoje autobusy na następnej aukcji? Skontaktuj się z nami: {0}.',
            p5: 'Dziękujemy!',
        },
        confirm: {
            success: {
                title: 'Twój adres e-mail został zweryfikowany.',
                p1: 'Dziękujemy za potwierdzenie adresu e-mail {email}.',
                p2: 'Twoje konto jest teraz aktywne i masz pełny dostęp do wszystkich naszych funkcji.',
                p3: 'Jeśli masz pytania lub potrzebujesz pomocy, skontaktuj się z zespołem obsługi klienta: {0}. Witamy na pokładzie!',
            },
            error: {
                title: 'Nie udało się potwierdzić adresu e-mail',
                p1: "We're sorry, but your email verification was unsuccessful. ",
                p2: 'Możliwe, że pojawił się problem z linkiem potwierdzającym. ',
                p3: 'Spróbuj ponownie, klikając przesłany przez nas link weryfikacyjny. Jeśli problem będzie się powtarzał, skontaktuj się z zespołem obsługi klienta: {0}.',
            },
            buyCta: 'Bierz udział w aukcjach',
            hpCta: 'Powrót do strony głównej',
        },
    },
    termsConditions: {
        link: "Terms {'&'} Conditions",
    },
    login: {
        email: 'Adres e-mail',
        password: 'Hasło',
        formSubtitle: 'Wprowadź swoje dane uwierzytelniające do Fleequid, aby móc brać udział w aukcjach',
        forgotPassword: 'Nie pamiętasz hasła?',
        unregistered: "Don't have a Fleequid account?",
        signupNow: 'Zarejestruj się teraz',
    },
    passwordReset: {
        request: {
            title: 'Zresetuj hasło',
            subTitle: 'Wpisz swój adres e-mail, a my wyślemy Ci wiadomość z prośbą o zresetowanie hasła.',
            submitButton: 'Wyślij wiadomość e-mail',
            onSuccessTitle: 'Prośba zaakceptowana',
            onSuccessDescription: 'Otrzymasz wiadomość e-mail z instrukcją resetowania hasła.',
        },
        reset: {
            title: 'Zresetuj hasło',
            description: 'Wprowadź nowe hasło. Po potwierdzeniu zmiany wszystkie aktywne sesje zostaną unieważnione.',
            newPassword: 'Nowe hasło',
            confirmNewPassword: 'Potwierdź nowe hasło',
            submitButton: 'Zmień hasło',
            updateSuccess: 'Hasło zostało pomyślnie zmienione',
            tokenExpired: 'Token do resetowania hasła wygasł.',
            tokenInvalid: 'Ten token resetowania hasła jest nieprawidłowy.',
            startOverDescription: 'Rozpocznij proces resetowania hasła, aby wygenerować nowy token.',
            startOverButton: 'Spróbuj ponownie',
        },
    },
    validation: {
        msg: {
            characters: 'Jeden lub więcej nieprawidłowych znaków',
            email: "Email should resemble user{'@'}domain.ext",
            incomplete: 'Pole {field} jest niekompletne',
            invalid: 'Pole {field} jest nieprawidłowe',
            invalidBid: 'Podana kwota jest nieprawidłowa',
            missing: 'Brakuje {field}',
            max: 'Maksymalna długość: {count} znak | Maksymalna długość: {count} znaki/znaków',
            min: 'Minimalna długość {count} znak | Minimalna długość: {count} znaki/znaków',
            length: 'Musi mieć długość dokładnie {count} znaku | Musi mieć długość dokładnie {count} znaków',
            passwordWeak: 'Hasło za słabe',
            passwordMatch: 'Hasła się nie zgadzają',
            phone: 'Używaj tylko cyfr, spacji i symbolu +',
            recaptcha: 'Błąd walidacji reCAPTCHA',
            required: 'Pole {field} jest wymagane',
        },
        hint: {
            password: {
                text: 'Zasady silnego hasła',
                rules: {
                    length: 'Co najmniej 8 znaków',
                    uppercase: '1 mała litera',
                    lowercase: '1 wielka litera',
                    symbol: '1 symbol',
                    number: '1 cyfra',
                },
            },
        },
    },
    profile: {
        title: 'Twój profil',
        companyData: {
            readOnlyDisclaimer: 'Dane firmy są tylko do odczytu. Aby poprosić o wprowadzenie zmian, skontaktuj się z nami: {0}.',
        },
        userData: {
            saveChanges: 'Potwierdź nowe dane',
            cancelChanges: 'Anuluj zmiany',
            updateSuccess: 'Dane osobowe zapisane poprawnie',
        },
        changePassword: {
            title: 'Zmień hasło',
            description: 'Zmiana hasła spowoduje unieważnienie wszystkich innych aktywnych sesji z wyjątkiem bieżącej.',
            currentPassword: 'Aktualne hasło',
            newPassword: 'Nowe hasło',
            confirmNewPassword: 'Potwierdź nowe hasło',
            submitButton: 'Zmień hasło',
            updateSuccess: 'Hasło zostało pomyślnie zmienione',
        },
        changeEmail: {
            title: 'Zmień adres e-mail',
            description: 'Otrzymasz wiadomość e-mail z linkiem do potwierdzenia nowego adresu e-mail. Zmiana adresu e-mail spowoduje unieważnienie wszystkich aktywnych sesji.',
            password: 'Aktualne hasło',
            email: 'Adres e-mail',
            submitButton: 'Zmień adres e-mail',
            updateSuccess: 'E-mail został pomyślnie zmieniony',
        },
    },
    sessionExpired: {
        title: 'Twoja sesja wygasła',
        description: 'Sesja została unieważniona, ponieważ zmieniono poświadczenia. Zaloguj się przy użyciu nowych danych.',
        close: 'Kontynuuj',
    },
    nuxtSiteConfig: {
        description: 'Kupuj lub sprzedawaj autobusy i pojazdy użytkowe poprzez aukcje: Dołącz do Fleequid i z łatwością uczestnicz w najwyższej jakości aukcjach B2B pojazdów przemysłowych.',
    },
};

export default pl;
