import datetimeFormatDe from '~/i18n/datetime/de';
import datetimeFormatEn from '~/i18n/datetime/en';
import datetimeFormatEs from '~/i18n/datetime/es';
import datetimeFormatFr from '~/i18n/datetime/fr';
import datetimeFormatIt from '~/i18n/datetime/it';
import datetimeFormatPl from '~/i18n/datetime/pl';
import { defaultLocale } from '~/i18n/i18nConfig';
import de from '~/i18n/locales/de';
import en from '~/i18n/locales/en';
import es from '~/i18n/locales/es';
import fr from '~/i18n/locales/fr';
import it from '~/i18n/locales/it';
import pl from '~/i18n/locales/pl';
import numberFormatDe from '~/i18n/number/de';
import numberFormatEn from '~/i18n/number/en';
import numberFormatEs from '~/i18n/number/es';
import numberFormatFr from '~/i18n/number/fr';
import numberFormatIt from '~/i18n/number/it';
import numberFormatPl from '~/i18n/number/pl';

export default defineI18nConfig(() => ({
    legacy: false,
    locale: defaultLocale,
    messages: {
        en,
        de: de as typeof en,
        es: es as typeof en,
        fr: fr as typeof en,
        it: it as typeof en,
        pl: pl as typeof en,
    },
    datetimeFormats: {
        en: datetimeFormatEn,
        de: datetimeFormatDe as typeof datetimeFormatEn,
        es: datetimeFormatEs as typeof datetimeFormatEn,
        fr: datetimeFormatFr as typeof datetimeFormatEn,
        it: datetimeFormatIt as typeof datetimeFormatEn,
        pl: datetimeFormatPl as typeof datetimeFormatEn,
    },
    numberFormats: {
        en: numberFormatEn,
        de: numberFormatDe as typeof numberFormatEn,
        es: numberFormatEs as typeof numberFormatEn,
        fr: numberFormatFr as typeof numberFormatEn,
        it: numberFormatIt as typeof numberFormatEn,
        pl: numberFormatPl as typeof numberFormatEn,
    },
}));
